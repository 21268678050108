<template>
  <div class="page--timedout" v-if="isTimedOut" @click.stop="() => {}">
    <div class="header">
      <h1 v-if="socials?.instagram && images">{{ $t('screensaver.cta') }}</h1>
      <h1 v-else>{{ $t('screensaver.ctaNoSocials') }}</h1>
    </div>
    <div class="content">
      <g-carousel
        v-if="images && images.length > 0"
        style="height: 900px"
        :slides="images.slice(0, 7).map((image) => ({ image }))"
      />

      <div class="socials">
        <div v-if="socials?.instagram">
          <g-icon-nav-insta />
          <small>@{{ socials?.instagram?.replace(/.*\//, '') }}</small>
        </div>
      </div>
    </div>
    <div class="footer">
      <div>
        <h2>{{ $t('screensaver.touch') }}</h2>
        <span>{{ $t('screensaver.toKnowMore') }}</span>
      </div>
      <cimg class="touch" src="/img/svg/touch.svg" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';

import axios from 'axios';
import project from 'glooh-globals/src/store/project';
import IconCamera from '@/components/icons/IconCamera.vue';

export default defineComponent({
  setup() {
    var Player = (window as any).Player;
    const images = ref<string[]>([]);
    const date = ref(new Date());
    const temp = ref<any>();
    const icon = ref<any>();
    const updateInterval = ref<any>();
    const timeInterval = ref<any>();
    const weatherLocation = project.config?.weather_hub;
    async function update() {
      let url = weatherLocation;
      if (!url) {
        return;
      }

      var Player = (window as any).Player;
      if (typeof Player !== 'undefined') {
        url = (await Player.cachedURL(url, { forceUpdate: true }))?.cachedURI ?? url;
      }
      const { data: weatherData } = await axios.get(url);
      icon.value = `owf owf-${weatherData.weather[0].id}`;
      temp.value = (weatherData.main.temp - 273.15).toFixed(1);
    }
    onMounted(async () => {
      update();
      updateInterval.value = setInterval(update, 60000);
      timeInterval.value = setInterval(() => (date.value = new Date()), 1000);
      try {
        let url = project.config.socials?.instagram_hub;

        if (!url) {
          return;
        }

        if (typeof Player !== 'undefined') {
          url = (await Player.cachedURL(url, { forceUpdate: true }))?.cachedURI ?? url;
        }

        const { data: instagram } = await axios.get(url);
        images.value = await Promise.all(
          (instagram ?? []).slice(0, 8).map(async (e: any) => {
            let url = 'http://tools.glooh.media/ig-2021/proxy.php?url=' + encodeURIComponent(e.url);
            if (typeof Player !== 'undefined') {
              url = (await Player.cachedURL(url))?.cachedURI ?? url;
            }
            return url;
          }),
        );
      } catch (e) {}
    });
    onBeforeUnmount(() => {
      if (updateInterval.value) {
        clearInterval(updateInterval.value);
      }
      if (timeInterval.value) {
        clearInterval(timeInterval.value);
      }
    });
    return { date, icon, temp, socials: project.config.socials, images, slug: import.meta.env.VITE_PROJECT_SLUG };
  },
  components: { IconCamera },
});
</script>

<style lang="scss" scoped>
.page {
  &--timedout {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    grid-template-columns: repeat(5, calc((100% - 8rem) * 0.2));
    grid-template-rows: 38% 18% 28% 10%;

    background: #000;
    color: #fdfcfc;
    gap: 2rem;

    position: fixed;
    top: 23rem;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    z-index: 1000;

    --carousel-background: #000;
    --carousel-border: transparent;

    .header,
    .content,
    .footer {
      width: 100%;
      padding: 1rem 4rem;

      background: black;
      color: #fdfcfc;
    }

    .content {
      display: flex;
      flex-direction: column;
      grid-gap: 2rem;
      flex: 1;
      padding: 0;

      .images {
        flex: 1;

        display: grid;
        grid-template-columns: 13rem 13rem 13rem 13rem;
        grid-auto-rows: 13rem;

        justify-content: space-between;
        align-content: space-between;

        .photo {
          flex-direction: row;
          text-align: left;
          gap: 4rem;
          text-transform: uppercase;

          svg {
            width: 6rem;
            height: 6rem;
          }

          .txt {
            * {
              margin: 0;
              padding: 0;
            }
            h2 {
              font-size: 2rem;
              line-height: 2rem;
            }
            small {
              font-size: 1.25rem;
              line-height: 1.25rem;
            }
          }
        }

        > * {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 2rem;
        }

        *:nth-child(1) {
          grid-column: 1 / span 2;
          grid-row: 1 / span 2;
        }
        *:nth-child(2) {
          grid-column: 3 / span 2;
          grid-row: 1 / span 2;
        }
        *:nth-child(8) {
          grid-column: 2 / span 2;
          background-color: #efefef;
        }
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .socials {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3rem;
        height: 2rem;

        div {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 1rem;

          small {
            font-size: 1.5rem;
            line-height: 1.5rem;
          }

          svg,
          img {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }

    .header {
      box-shadow: 0 0 12px 4px rgba(0 0 0 / 0.05);
      font-size: 2rem;
      padding: 0;
      margin: 0;
      font-family: 'HallOfFun';
    }

    .footer {
      position: relative;
      height: 20rem;
      background-color: #fff;
      background: black;
      color: #fdfcfc;

      display: flex;
      flex-direction: row;
      gap: 8rem;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 12px 4px rgba(0 0 0 / 0.05);
    }

    .touch {
      width: 8rem;
      height: 8rem;

      animation: shake 4.1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
      transform: translate3d(0, 0, 0);
      backface-visibility: hidden;
      perspective: 1000px;
    }
  }
}
</style>
