import { App } from 'vue';

export default {
  install() {
    var Player = (window as any).Player;
    if (typeof Player != 'undefined' && Player.active) {
      this.init();
      setInterval(this.init, 5000);
    }
  },
  async init() {
    try {
      var Player = (window as any).Player;
      const cache_ping = await (await fetch(`http://localhost:${Player.CACHE_PORT}/ping`)).text();
      const server_ping = await (await fetch(`http://localhost:${Player.SERVER_PORT}/ping`)).text();
      if (cache_ping !== 'Pong' || server_ping !== 'Pong') throw "Unexpected Value"
    } catch(e) {
      console.error(e);
      var Player = (window as any).Player;
      Player.ipc?.send('reload', "Failed to ping.");
    }
  }
};
