<template>
  <div class="modal-zone" :id="`${zone}-zone`" />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    zone: {
      type: String,
      default: ""
    }
  }
});
</script>

<style lang="scss">
.modal-zone {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  pointer-events: none;

  * {
    pointer-events: all;
  }
}
</style>
