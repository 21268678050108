import { App } from 'vue';

import IconLoading from './icons/IconLoading.vue';
import IconHome from './icons/IconHome.vue';
import IconDisabled from './icons/IconDisabled.vue';
import IconLanguage from './icons/IconLanguage.vue';
import IconClose from './icons/IconClose.vue';
import IconSearch from './icons/IconSearch.vue';
import IconDirections from './icons/IconDirections.vue';
import IconLinearScale from './icons/IconLinearScale.vue';
import IconMapLocation from './icons/IconMapLocation.vue';
import IconNavigation from './icons/IconNavigation.vue';
import IconTripOrigin from './icons/IconTripOrigin.vue';
import IconAdd from './icons/IconAdd.vue';
import IconClear from './icons/IconClear.vue';
import IconMoreVert from './icons/IconMoreVert.vue';
import IconAddMapLocation from './icons/IconAddMapLocation.vue';
import IconArrowLeft from './icons/IconArrowLeft.vue';
import IconArrowRight from './icons/IconArrowRight.vue';

import IconNavVilHome from './icons/IconNavVilHome.vue';
import IconNavVilSearch from './icons/IconNavVilSearch.vue';
import IconNavVilStores from './icons/IconNavVilStores.vue';
import IconNavVilServices from './icons/IconNavVilServices.vue';
import IconNavVilPlan from './icons/IconNavVilPlan.vue';
import IconNavVilDisabled from './icons/IconNavVilDisabled.vue';

import Button from './controls/Button.vue';
import NavBar from './controls/NavBar.vue';
import Keyboard from './controls/Keyboard.vue';

import Swiper from './containers/swiper/Swiper.vue';
import Slide from './containers/swiper/Slide.vue';
import Carousel from './containers/carousel/Carousel.vue';
import Title from './containers/title/Title.vue';
import Modal from './containers/modal/Modal.vue';
import ModalZone from './containers/modal/ModalZone.vue';

export default {
  Button,
  NavBar,
  Swiper,
  Slide,
  Carousel,
  Title,
  Modal,
  ModalZone,
  Keyboard,
  icons: {
    IconLoading,
    IconHome,
    IconDisabled,
    IconLanguage,
    IconClose,
    IconSearch,
    IconDirections,
    IconLinearScale,
    IconMapLocation,
    IconNavigation,
    IconTripOrigin,
    IconAdd,
    IconClear,
    IconMoreVert,
    IconAddMapLocation,
    IconArrowLeft,
    IconArrowRight,
    IconNavVilHome,
    IconNavVilSearch,
    IconNavVilStores,
    IconNavVilServices,
    IconNavVilPlan,
    IconNavVilDisabled
  },
};

export function installIcons(app: App, _options: any) {
  app.component('g-icon-loading', IconLoading);
  app.component('g-icon-home', IconHome);
  app.component('g-icon-disabled', IconDisabled);
  app.component('g-icon-language', IconLanguage);
  app.component('g-icon-close', IconClose);
  app.component('g-icon-search', IconSearch);
  app.component('g-icon-directions', IconDirections);

  app.component('g-icon-linear-scale', IconLinearScale);
  app.component('g-icon-map-location', IconMapLocation);
  app.component('g-icon-navigation', IconNavigation);
  app.component('g-icon-trip-origin', IconTripOrigin);

  app.component('g-icon-clear', IconClear);
  app.component('g-icon-add', IconAdd);
  app.component('g-icon-more-vert', IconMoreVert);
  app.component('g-icon-add-map-location', IconAddMapLocation);

  app.component('g-icon-arrow-left', IconArrowLeft);
  app.component('g-icon-arrow-right', IconArrowRight);

  app.component('g-icon-nav-vil-home', IconNavVilHome);
  app.component('g-icon-nav-vil-search', IconNavVilSearch);
  app.component('g-icon-nav-vil-stores', IconNavVilStores);
  app.component('g-icon-nav-vil-services', IconNavVilServices);
  app.component('g-icon-nav-vil-plan', IconNavVilPlan);
  app.component('g-icon-nav-vil-disabled', IconNavVilDisabled);
}

export function installControls(app: App, _options: any) {
  app.component('g-button', Button);
  app.component('g-navbar', NavBar);
  app.component('g-keyboard', Keyboard);
}

export function installContainers(app: App, _options: any) {
  app.component('g-swiper', Swiper);
  app.component('g-slide', Slide);
  app.component('g-carousel', Carousel);
  app.component('g-title', Title);

  app.component('g-modal', Modal);
  app.component('g-modal-zone', ModalZone);
}

export function install(app: App, options: any) {
  console.log('Installing Components');
  installIcons(app, options);
  installControls(app, options);
  installContainers(app, options);
}
