<template>
  <div class="map--controller">
    <MapProvider ref="provider" v-on:place-selected="(e) => placeSelected(e)" @loaded="mapLoaded" />
    <div class="place-info" v-if="viewingPlace">
      <!--<h5 class="title">{{ getLocaleValue(viewingPlace.title) }}</h5>-->
      <div class="logo">
        <cimg :src="viewingPlace.logo" />
      </div>
      <h5 class="link">
        <router-link :to="{ name: 'store', params: { id: viewingPlace.id } }">
          {{ $t('general.learnMore') }}
        </router-link>
      </h5>
      <h5 class="link">
        <a href="#" @click="setDestination()">{{ $t('map.takeMe') }}</a>
      </h5>
    </div>

    <div class="zoom-btn-group">
      <div @click="resetMap" class="zoom-btn">
        <img src="/img/pictos/map.svg" alt="" />
      </div>
      <div @click="handleZoom('plus')" class="zoom-btn">+</div>
      <div @click="handleZoom('minus')" class="zoom-btn">﹣</div>
    </div>

    <div v-if="floors.length > 1" class="floor-btn-group">
      <div v-for="(floor, index) in floors" :key="floor.index" @click="map?.goToFloor(floor.name)" class="floor-btn">
        L{{ index }} {{ $t(floor.name) }}
      </div>
    </div>

    <div class="navigation-group">
      <div class="nav-wrapper">
        <div v-if="navigation" class="nav">
          <div v-if="qrCode" class="qr-contnt">
            <span>
              {{ $t('map.qrTheMap') }}
              <br />
              {{ $t('map.qrOnYour') }}
            </span>
            <img :src="qrCode" />
          </div>
          <div :class="['card-content', 'navigation', 'vg-shifted']">
            <div class="instructions">
              <cimg id="instructionIcon" :src="currentInstructionIcon" />
              <span>
                {{ currentInstructionDetail }}
              </span>
            </div>
            <div class="buttons">
              <button @click.stop="goToPreviousInstruction" :disabled="instructionIndex === 0">
                {{ $t('map.navigation.previous') }}
              </button>
              <button @click.stop="goToNextInstruction" :disabled="instructionIndex === nbInstructions - 1">
                {{ $t('map.navigation.next') }}
              </button>
              <button @click.stop="clear">
                {{ $t('map.navigation.cancel') }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="nav">
          <div v-if="qrCodeDefault" class="qr-contnt">
            <span>
              {{ $t('map.qrTheMap') }}
              <br />
              {{ $t('map.qrOnYour') }}
            </span>
            <img :src="qrCodeDefault" />
          </div>
        </div>
        <div id="floorChangeTags"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, onUpdated, ref } from 'vue';
import { useRoute } from 'vue-router';

import MapProvider from '@/components/map/MapProvider.vue';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import { getKiosk } from 'glooh-globals/src/store/project';
import { Cache } from 'glooh-globals/src/mixins/misc/cache';
import { useAnalytics } from 'glooh-globals/src/plugins/misc/analytics';
import QRCode from 'qrcode';

export default defineComponent({
  components: {
    MapProvider,
  },
  setup() {
    const route = useRoute();
    const instanceRef = ref(Math.random().toFixed(3));

    const provider = ref<typeof MapProvider>(),
      viewingPlace = ref(),
      activePlace = ref(),
      activeRoute = ref(),
      instructionIndex = ref<number>(0),
      qrCode = ref<string>(),
      qrCodeDefault = ref<string>();

    const map = computed(() => provider.value?.map);
    const floors = computed(() => map.value?.getFloors() ?? []);
    const navigation = computed(() => activeRoute.value?.navigation);
    const nbInstructions = computed(() => navigation.value?.getNbInstructions() ?? 0);
    const currentInstructionData = computed(() => navigation.value?.getInstructionData(instructionIndex.value));
    const currentInstructionIcon = computed(() =>
      currentInstructionData.value?.icon ? `/media/${currentInstructionData.value?.icon}` : '',
    );
    const currentInstructionDetail = computed(() => currentInstructionData.value?.detail ?? '');

    function resetMap() {
      Cache.store.mapviewer.camera.rotation = getKiosk().camera.rotation;
      Cache.store.mapviewer.camera.pitch = getKiosk().camera.pitch;
      map.value.clearRoute();
      map.value.resetActivePlace();

      activePlace.value = undefined;
      viewingPlace.value = undefined;
      activeRoute.value = undefined;
      instructionIndex.value = 0;

      const place = map.value.getPlaceForShop(route.params?.placeId as string);
      map.value.setActivePlace(place);
      map.value.setDestination(place);
      //map.value.goToPlace(place, Kiosk.camera);
      //map.value.setActivePlace(place);
    }

    function handleZoom(mode) {
      let position = Cache.store.mapviewer.camera.position;
      if (mode == 'plus') {
        position.radius -= 50;
      } else {
        position.radius += 50;
      }
      Cache.store.mapviewer.camera.goTo(position);
    }

    function placeSelected(placeId: string) {
      const placeInfo = Cache.store.places[placeId];
      console.log('viewingPlace', instanceRef.value, map.value, placeId, placeInfo, !!placeInfo?.shop);
      if (placeInfo?.shop) {
        const shop = placeInfo.shop;
        viewingPlace.value = shop;
        activePlace.value = placeId;
        map.value.goToPlace(shop.mapwize_id);
        clear();
      }
    }

    async function setDestination() {
      await map.value.setDestination(false, activePlace.value);
      activeRoute.value = Cache.store.currentRoute;
      instructionIndex.value = navigation.value.getCurrentInstructionIndex();
      await setQRCode(`dst=${encodeURIComponent(activePlace.value)}`, qrCode);
    }

    async function setQRCode(dest: string, qrCode: Ref<QRCode>) {
      try {
        const { getState } = useAnalytics();
        const sessionId = getState('user.userId');
        const kisokId = getState('user.traits.kiosk');
        const project = (import.meta as any).env.VITE_PROJECT_SLUG;
        const url =
          `${(import.meta as any).env.VITE_MOBILE_MAP_URL}/mall/${project}?sessionId=${sessionId}&kioskId=${kisokId}&` +
          `src=you_are_here&` +
          dest;
        qrCode.value = await QRCode.toDataURL(url, { width: 150 });
      } catch (e) {
        console.log(e);
      }
    }

    function clear() {
      map.value.clearRoute();
      activeRoute.value = undefined;
    }

    function goToPreviousInstruction() {
      navigation.value.displayPrevInstruction();
      instructionIndex.value = navigation.value.getCurrentInstructionIndex();
    }

    function goToNextInstruction() {
      navigation.value.displayNextInstruction();
      instructionIndex.value = navigation.value.getCurrentInstructionIndex();
    }

    function mapLoaded() {
      if (route.params?.placeId as string) {
        const place = map.value.getPlaceForShop(route.params?.placeId as string);
        if (place) setDestination(place);
      }
    }

    onMounted(() => {
      if (route.params?.placeId as string) {
        const place = map.value.getPlaceForShop(route.params?.placeId as string);
        if (place) setDestination(place);
      }

      setQRCode('', qrCodeDefault);
    });

    return {
      getLocaleValue,
      provider,
      viewingPlace,
      activeRoute,
      instructionIndex,
      qrCode,
      qrCodeDefault,
      map,
      floors,
      navigation,
      nbInstructions,
      currentInstructionData,
      currentInstructionIcon,
      currentInstructionDetail,
      resetMap,
      handleZoom,
      placeSelected,
      setDestination,
      clear,
      goToPreviousInstruction,
      goToNextInstruction,
      mapLoaded,
    };
  },
});
</script>

<style lang="scss" scoped>
.map {
  &--controller {
    position: relative;
    overflow: hidden;
    display: flex;

    .floor-btn-group {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;
      margin-left: 2rem;

      .floor-btn {
        width: 16rem;
        height: 5rem;
        font-size: 1.75rem;
        font-weight: bold;
      }
    }

    .zoom-btn-group {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 10;

      .zoom-btn {
        & > img {
          width: 50%;
        }
        width: 5rem;
        height: 5rem;
        font-size: 3rem;
        font-weight: bold;
      }
    }

    .zoom-btn-group .zoom-btn,
    .floor-btn-group .floor-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 1rem;
      background: #f1f1f1;
      cursor: pointer;
      &:active {
        background: #a1a1a1;
      }
    }

    .navigation-group {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      pointer-events: none;
      z-index: 10;
    }

    .nav-wrapper {
      width: 100%;
      pointer-events: none;

      .nav {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-end;

        font-size: 1rem;
        width: min-content;
        padding: 1rem;
        margin: 0 auto;
        gap: 20px;

        .card-content {
          pointer-events: all;
          width: 100%;
        }

        .qr-contnt {
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          min-height: 180px;
          min-width: 150px;
          max-width: 150px;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #f1f1f1;
          backdrop-filter: blur(10px);
        }

        .navigation {
          border-radius: 0.5rem;
          background: #ffffff;
          backdrop-filter: blur(10px);
        }

        .instructions {
          padding: 1rem;
          display: flex;
          justify-content: space-between;
          min-width: 500px;

          font-size: 1rem;
          line-height: 1rem;
          gap: 1rem;

          #instructionIcon {
            width: 25px;
            height: 25px;
          }
        }

        button {
          padding: 0.5rem 1rem;
          color: var(--theme-color);
          border: 0;

          &:not(:disabled):hover {
            background: rgba(0, 0, 0, 0.2);
          }

          &:not(:disabled) {
            cursor: pointer;
          }
        }

        button.disabled,
        button:disabled,
        button[disabled] {
          color: gray;
        }

        .buttons {
          display: flex;
          justify-content: center;
          background: #f1f1f1;
          gap: 0.5rem;
          padding: 1rem;
          border-radius: 0.5rem;
        }
      }
    }

    .place-info {
      position: absolute;
      top: 2rem;
      right: 2rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h5 {
        width: 100%;
        background: #e5e6e1;
        padding: 0;

        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

        &.title {
          padding: 0.5rem;
          font-size: 1rem;
          line-height: 1rem;
          margin-bottom: 0.5rem;
        }

        &.link {
          margin-top: 0.5rem;
          font-size: 1rem;
          line-height: 1rem;
        }

        a {
          display: block;
          padding: 0.5rem;
        }
      }

      .logo {
        width: 14rem;
        height: 14rem;
        padding: 1rem;
        background: #fff;
        box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  &--wrapper {
    flex: 1;
    overflow: hidden;
  }
}
</style>
