<template>
  <div class="page--stores">
    <h1>NOS OFFERS</h1>
    <Filter :stores="shopsWithPromos">
      <template v-slot:default="store">
        <g-promo-card v-for="(promo, i) in store.promos" :key="i" class="swiper-slide" :promo="promo" />
      </template>
    </Filter>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Filter from '@/components/misc/Filter.vue';

import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Filter,
  },
  computed: {
    shopsWithPromos(): any[] {
      return project.shops.all().filter((shop: any) => shop.promos.length > 0);
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--stores {
    --button-padding: 1vmax 4vmax;
    --navbar-display: flex;
    --button-background-active: transparent;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    h1 {
      font-size: 3vmax;
      padding: 0;
    }

    :deep(.store-type) {
      margin-bottom: 2vmax;

      .navbar {
        gap: 2vmax;
      }
    }
  }
}
</style>
