<template>
  <div class="loading-wrapper">
    <IconLoading width="64" height="64" />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import IconLoading from 'glooh-globals/src/components/icons/IconLoading.vue';
export default defineComponent({
  components: {
    IconLoading
  }
});
</script>

<style lang="scss" scoped>
.loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  background: var(--app-background);
  color: var(--app-foreground);
}
</style>
