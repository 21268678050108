<template>
  <div id="camera" :class="`c${index}`">
    <div class="card">
      <div class="feed">
        <video ref="video" width="640" height="640" autoPlay="true" id="video" />
        <img ref="filter" id="filter" width="640" height="640" alt="filter" :src="`/${slug}/img/filter.png`" />
        <canvas ref="canvas" id="preview" width="640" height="640" />
      </div>
    </div>

    <transition appear enter-active-class="animated slideInRight" leave-active-class="animated slideOutLeft">
      <div v-if="index === 0" key="0" class="wrapper">
        <div class="topPanel">
          <g-title :title="$t('camera.title')" :subTitle="$t('camera.subTitle')">
            <template v-if="index > 0 && index < 3" #left>
              <g-button icon="chevron_left" @click="prevIndex" />
            </template>
          </g-title>

          <div class="instructions">
            <span>1°</span>
            <span>{{ $t('camera.instructions.step1') }}</span>
            <span>2°</span>
            <span>{{ $t('camera.instructions.step2') }}</span>
            <span>3°</span>
            <span>{{ $t('camera.instructions.step3') }}</span>
            <div v-if="project.config?.cam_broadcast_screen">
              <span>4°</span>
              <span>
                {{ $t('camera.instructions.step4.part1') }}
                <br />
                {{ $t('camera.instructions.step4.part2') }}
              </span>
            </div>
          </div>
        </div>

        <transition appear enter-active-class="animated zoomIn" leave-active-class="animated zoomOut">
          <h1 v-if="photo" :key="timer" class="countdown">{{ timer }}</h1>
        </transition>

        <g-button v-vis="!photo" class="takePhoto" icon="svg" @click="startCountdown" :disabled="photo">
          {{ $t('camera.instructions.action') }}
          <template #icon>
            <IconCamera style="font-size: 60px" />
          </template>
        </g-button>
      </div>

      <div v-else-if="index === 2" key="2" class="wrapper">
        <div class="end">
          <div class="thanks">
            <span>{{ $t('camera.confirmation.title') }}</span>
            <span>{{ $t('camera.confirmation.social_medias') }}</span>
          </div>

          <div v-if="project.config.socials?.instagram" class="insta">
            <span class="TextItem">@{{ project.config.socials?.instagram?.replace(/.*\//, '') }}</span>
          </div>

          <g-button class="nextBtn" @click="nextIndex">
            <template #icon>
              <IconArrowRight style="font-size: 60px" />
            </template>
          </g-button>
        </div>
      </div>

      <div v-else-if="index === 1" key="1" class="wrapper">
        <div class="topPanel">
          <g-button v-vis="!photo" class="reTakePhoto" icon="svg" @click="reset(0)" :disabled="photo">
            <template #icon>
              <IconCamera style="font-size: 60px" />
            </template>
          </g-button>
        </div>

        <div class="send">
          <g-title
            class="page-header"
            :title="$t('camera.reception.title')"
            :subTitle="$t('camera.reception.subTitle')"
          />

          <h1 v-if="error" :style="{ textAlign: 'center', color: '#c80a3c', padding: 0 }">
            {{ error }}
          </h1>

          <div class="input-search" :style="{ width: '90%' }">
            <input v-model="email" type="text" :placeholder="'Email'" spellcheck="false" />
          </div>

          <div class="keyboard-wrapper">
            <g-keyboard :input="email" @onChange="onChange" />
          </div>

          <div class="checkboxes">
            <label class="container">
              {{ $t('camera.cgu') }}
              <input type="checkbox" v-model="terms" />
              <span class="checkmark"></span>
            </label>

            <label class="container" v-if="project.config?.cam_subscribe_newsletter">
              {{ $t('camera.newsletter') }}
              <input type="checkbox" v-model="subscribe" />
              <span class="checkmark"></span>
            </label>

            <label class="container" v-if="project.config?.cam_broadcast_screen">
              {{ $t('camera.screen_diffusion') }}
              <input type="checkbox" v-model="use" />
              <span class="checkmark"></span>
            </label>
          </div>

          <g-button class="nextBtn" @click="nextIndex">
            <template #icon>
              <IconArrowRight style="font-size: 60px" />
            </template>
          </g-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
#camera {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;

  .wrapper {
    position: absolute;
    top: 1rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .countdown {
    position: absolute;
    top: 50%;
    left: 50%;

    background: rgba(white, 0.75);
    padding: 2vmax;
    margin: -6vmax;
    width: 12vmax;
    height: 12vmax;
    font-size: 6vmax;
    border-radius: 6vmax;
    box-shadow: 0 0 0.5vmax rgba(black, 0.4);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .takePhoto,
  .reTakePhoto {
    width: 40%;
    margin: 0 auto;
    font-weight: bold;

    img {
      margin-right: 2vmax;
    }
  }

  .reTakePhoto {
    width: auto;
    padding: 1vmax 3vmax;
  }

  .card {
    position: absolute;
    top: 58%;
    left: 50%;

    background: white;
    width: min-content;
    padding: 1vmax 1vmax 5vmax;
    margin: 0 auto 3vmax;
    box-shadow: 0 0 0.5vmax rgba(black, 0.4);
    transform: rotate(5deg) translate(-50%, -50%) scale(0.9);
    transition: transform 0.5s, opacity 0.5s;

    .feed {
      position: relative;
      width: 640px;
      height: 640px;
      background: black;
      overflow: hidden;

      video {
        object-fit: cover;
      }

      * {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
      }
    }
  }

  .topPanel {
    display: grid;
    grid-template-columns: 60% 40%;
    grid-template-rows: 100%;
    align-items: center;

    height: 18vmax;
    width: 100%;
    overflow: hidden;

    .title-wrapper {
      margin: 0;
      padding: 0;
    }

    .instructions {
      width: 100%;
      height: min-content;

      display: grid;
      grid-template-columns: 30px auto;
      grid-template-rows: repeat(4, auto);

      justify-content: center;
      align-items: flex-start;

      span:nth-child(2n + 1) {
        font-weight: bold;
        font-family: 'Didot';
        font-size: 30px;
        line-height: 30px;
      }

      span:nth-child(2n) {
        font-size: 30px;
        line-height: 30px;
        font-weight: 400;
        min-height: 30px;
        margin: 0 0 1vmax 1vmax;
        text-align: left;
      }
    }
  }

  .send {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    gap: 1.5rem;

    .checkboxes {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    .keyboard-wrapper {
      overflow: hidden;
      height: 7vmax;

      --keyboard-background: black;
      --keyboard-background-numpad: #888;

      .simple-keyboard {
        margin: 0;
        padding: 0;
        transform: scale(0.5) translate(0, -8rem);
      }
    }

    .input-search {
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1.5vmax;
      line-height: 1.5vmax;
      background: #f5f5f3;
      color: #27263a;
      padding: 1vmax;
      gap: 1vmax;
      width: 100%;

      input {
        border: 0;
        outline: none;
        background: transparent;
        flex: 1;
        color: #27263a;
        &::placeholder {
          text-transform: uppercase;
          color: #27263a;
        }
      }
    }

    .page-header {
      .title {
        font-size: 3vh;
        line-height: 3vh;
      }
      .subtitle {
        margin-top: 0.5vh;
        font-size: 1.125vh;
      }
    }

    .container {
      display: flex;
      position: relative;
      padding-left: 45px;
      cursor: pointer;
      font-size: 22px;
      line-height: 22px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      width: 90%;

      justify-content: flex-start;
      align-items: center;
      text-align: left;
    }

    /* Hide the browser's default checkbox */
    .container input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      height: 25px;
      width: 25px;
      margin: auto;
      background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .container:hover input ~ .checkmark {
      background-color: #ccc;
    }

    /* When the checkbox is checked, add a blue background */
    .container input:checked ~ .checkmark {
      background-color: #2196f3;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
      content: '';
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    .container input:checked ~ .checkmark:after {
      display: block;
    }

    /* Style the checkmark/indicator */
    .container .checkmark:after {
      left: 9px;
      top: 5px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  .end {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .insta {
      display: block;
      text-transform: uppercase;
      font-size: 32px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 2px;
      margin-left: 10px;
      margin-bottom: 100px;
    }

    .thanks {
      width: 70%;
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: repeat(4, auto);

      justify-content: center;
      align-items: flex-start;
      margin-bottom: 100px;
      text-align: center;

      span {
        font-size: 40px;
        line-height: 40px;
        font-weight: 400;
        min-height: 80px;
        margin-bottom: 50px;
      }
    }
  }

  &.c1 {
    .card {
      transform: rotate(12deg) translate(-20vmin, -42vmax) scale(0.5);
    }
  }

  &.c2 {
    .card {
      transition: transform 0.5s 1s, opacity 0.5s;
      transform: rotate(5deg) translate(50%, -50%);
      opacity: 0;
    }
  }
}
</style>

<script lang="ts" setup>
import { ref, nextTick, onMounted, onBeforeUnmount } from 'vue';
import IconCamera from '@/components/icons/IconCamera.vue';
import IconArrowRight from 'glooh-globals/src/components/icons/IconArrowRight.vue';

import project from 'glooh-globals/src/store/project';

const video = ref(),
  canvas = ref(),
  filter = ref();

const index = ref(1);
const timer = ref(5);
const photo = ref(false);
const interval = ref<number>();
const captured = ref(undefined);
const email = ref('');
const terms = ref(false);
const subscribe = ref(false);
const use = ref(false);
const error = ref('');

const stream = ref();

const slug = import.meta.env.VITE_PROJECT_SLUG;

function prevIndex() {
  index.value--;
  if (index.value === 0) {
    reset(0);
  }
}

async function nextIndex() {
  if (index.value === 1) {
    if (await sendPhoto()) {
      index.value++;
    }
  } else if (index.value === 2) {
    reset();
  } else {
    index.value++;
  }
}

function startCountdown() {
  if (!photo.value || timer.value === 0) {
    let canvasCtx = canvas.value.getContext('2d');
    canvasCtx.clearRect(0, 0, canvas.value.width, canvas.value.height);
    captured.value = undefined;

    photo.value = true;
    timer.value = 5;
    interval.value = setInterval(() => countdown(), 1000) as unknown as number;
  }
}

function countdown() {
  if (timer.value === 0) {
    clearInterval(interval.value);
    takePhoto();
  } else {
    timer.value = Math.max(0, timer.value - 1);
  }
}

function takePhoto() {
  let canvasCtx = canvas.value.getContext('2d');
  let videoWidth = 640 * (video.value.videoWidth / video.value.videoHeight),
    videoOffset = (videoWidth - 640) / 2;

  canvasCtx.drawImage(video.value, -videoOffset, 0, videoWidth, 640);
  canvasCtx.drawImage(filter.value, 0, 0, 640, 640);
  captured.value = canvas.value.toDataURL('image/png');
  photo.value = false;
  index.value = 1;
}

function onChange(input: string) {
  email.value = input;
}

function registerCamera() {
  nextTick(async () => {
    if (navigator.mediaDevices.getUserMedia) {
      let _stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      video.value.srcObject = _stream;
      stream.value = _stream;
    } else {
      console.log('No Media!');
    }
  });
}

function stopCamera() {
  video.value.srcObject = undefined;
  stream.value?.getTracks()?.forEach(function (track: any) {
    if (track.readyState == 'live') {
      track.stop();
    }
  });
}

async function sendPhoto() {
  if (!terms.value) {
    error.value = 'Acepta los términos y condiciones.';
    return false;
  }

  if (!email.value) {
    error.value = 'Por favor, introduzca su dirección de correo electrónico.';
    return false;
  }

  const { status } = await fetch('https://snap.glooh.app/process', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      activation: project.config?.cam_activation,
      email: email.value,
      image: captured.value,
      terms: terms.value,
      subscribe: subscribe.value,
      show_image: use.value,
    }),
  });

  if (status !== 200) {
    error.value = 'Failed to upload image!';
    return false;
  }

  return true;
}

function reset(i = 0) {
  index.value = i;
  timer.value = 5;
  photo.value = false;
  interval.value = -1;
  captured.value = undefined;
  email.value = '';
  terms.value = false;
  subscribe.value = false;
  use.value = false;
  error.value = '';
  if (i === 0) {
    registerCamera();
  } else {
    stopCamera();
  }
}

onBeforeUnmount(() => {
  clearInterval(interval.value);
  stopCamera();
});

onMounted(() => {
  reset(0);
});
</script>
