<template>
  <Content modal>
    <template #header>
      <h1>{{ getLocaleValue(service?.title) }}</h1>
    </template>
    <div class="page--service">
      <div class="left">
        <div class="desc">
          <div class="desc-content" v-html="service?.description_long"></div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import _ from 'lodash';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import MapProvider from '@/components/map/MapProvider.vue';
import project from 'glooh-globals/src/store/project';
import Content from '@/components/layout/content/Content.vue';
import IconHere from '@/components/icons/IconHere.vue';

export default defineComponent({
  components: {
    MapProvider,
    Content,
    IconHere,
  },
  props: {
    id: {
      type: Number,
    },
  },
  computed: {
    serviceId(): number {
      return this.id || parseInt(this.$route.params.id as string);
    },
    service(): any {
      return project.services.find(this.serviceId);
    },
    weekdays() {
      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    },
    weekday() {
      return moment().locale('en').format('dddd').toLowerCase();
    },
  },
  methods: {
    getLocaleValue,
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--service {
    display: flex;
    flex-direction: row;
    gap: 1vmax;

    flex: 1;
    flex-direction: column;
    width: 100%;
    font-size: 1.5vmax;
    line-height: 1.5vmax;
    color: black;
    background: white;
    overflow: visible;
    height: 100%;
    padding: 2rem;

    .takeMeThere {
      background: #222d33;
      border: none;
      padding: 1.25rem 5rem;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 1.5rem;

      span {
        font-size: 2rem;
      }

      svg {
        height: 2rem;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      font-size: 1.15rem;
      gap: 3rem;
      overflow: auto;
      height: 100%;
      text-align: left;
    }
  }
}
</style>
