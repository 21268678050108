<template>
  <g-button class="top" :to="routeObject">
    <div class="container_service">
      <div class="icon_container">
        <cimg class="icone" :src="service?.logo" />
      </div>
      <div class="text">{{ localTitle }}</div>
    </div>
  </g-button>
</template>

<script setup lang="ts">
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import { computed } from 'vue';

const props = defineProps({
  service: {
    type: Object,
    default: undefined,
  },
  store: {
    type: Object,
    default: undefined,
  },
  to: {
    type: Object,
    default: undefined,
  },
});

const routeObject = computed(() => {
  return {
    name: 'service',
    params: {
      id: props.service?.id,
    },
  };
});

const localTitle = computed(() => {
  return getLocaleValue(props.service?.title);
});
</script>

<style scoped>
.container_service {
  padding: 16px;
  width: 150px;

  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
  border-radius: 5px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 8px 8px;
  grid-auto-flow: row;
  grid-template-areas:
    'icon_container'
    'text';
}

.icon_container {
  justify-self: center;
  align-self: stretch;
  grid-area: icon_container;
}

.text {
  justify-self: center;
  align-self: center;
  grid-area: text;
  font-size: 16px;
  text-transform: uppercase;
  /*color: rgba(var(--theme-color-rgb), 0.85);*/
  color: black;
}

.icone {
  height: 48px;
}
</style>
