<template>
  <Content>
    <template #header>
      <h1>{{$t('openings.title')}}</h1>
    </template>
    <div class="page--hours">
      <div v-for="(block, i) in hours" :key="i" class="hours-block">
        <div class="title">
          <h1>{{ block.title }}</h1>
        </div>
        <div class="hours">
          <div
            v-for="(hour, h) in Object.keys(block.hours)"
            :key="h"
            class="day"
            :class="{ closed: block.hours[hour] === $t('openings.closed'), active: hour === day }"
          >
            <span>{{ $t(`days.${hour.slice(0, 3)}`) }}</span>
            <span>{{ block.hours[hour] }}</span>
          </div>
        </div>
        <div v-if="block.comment" class="comments">
          <p>{{ block.comment }}</p>
        </div>
      </div>

      <div class="special-hours">
        <div class="img-wrapper">
          <img :src="`/${slug}/img/hours.jpg`" />
          <h1>{{$t('openings.specialDays')}}</h1>
        </div>
        <div class="columns">
          <div class="column">
            <div v-for="(sh, h) in special_hours" :key="h" class="day" :class="{ closed: sh.status === $t('openings.closed') }">
              <span>{{ sh.title }}</span>
            </div>
          </div>
          <div class="column">
            <div v-for="(sh, h) in special_hours" :key="h" class="day" :class="{ closed: sh.status === $t('openings.closed') }">
              <span>{{ sh.date }}</span>
            </div>
          </div>
          <div class="column">
            <div v-for="(sh, h) in special_hours" :key="h" class="day" :class="{ closed: sh.status === $t('openings.closed') }">
              <span>{{ sh.status }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import moment from 'moment';

import Content from '@/components/layout/content/Content.vue';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Content,
  },
  setup() {
    const day = computed<string>(() => moment().format('dddd').toLowerCase());
    return {
      day,
      hours: project.config.opening_hours ?? [],
      special_hours: project.config.special_openings ?? [],
      slug: import.meta.env.VITE_PROJECT_SLUG,
    };
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--hours {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    background: white;
    padding: 2rem;
    flex: 1;
    gap: 2rem;

    width: 100%;
    flex-wrap: wrap;
    overflow: auto;

    .special-hours {
      display: flex;
      gap: 2rem;

      flex: 1;
      flex-basis: 66%;
      max-width: 66%;

      .img-wrapper {
        position: relative;
        h1 {
          position: absolute;
          top: 4rem;
          left: 2rem;
          right: 0;
          bottom: 0;

          display: flex;

          font-size: 3rem;
          line-height: 3rem;
          color: white;
          text-align: left;
        }
      }

      .columns {
        display: flex;
        box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);
        .column {
          white-space: nowrap;
          min-width: 10rem;
          text-align: left;
          &:first-child {
            flex: 1;
          }
        }
      }
    }

    .hours-block {
      flex: 1;
      flex-basis: 30%;
      max-width: 30%;
      box-shadow: 0 0 8px 0 rgb(0 0 0 / 12%);

      .title {
        padding: 0.25rem;
        border-bottom: 1px solid #efefef;
        padding-left: 2rem;
        font-size: 1rem;
        h1 {
          padding: 0;
          text-align: left;
        }
      }

      .hours {
        background: #fefefe;
      }

      .comments {
        padding: 1rem;
        background: #dfdfdf;
      }
    }

    .day {
      display: flex;
      justify-content: space-between;
      padding: 0.55rem;
      border-bottom: 1px solid #efefef;

      &.active {
        background: var(--theme-color);
        color: white;
      }

      &.closed {
        background: rgba(#fefefe, 0.4);
        color: #bbb;
      }
    }
  }
}
</style>
