<template>
  <div v-if="showFilter" class="filter">
    <g-navbar :routes="routes" param="filter" />
  </div>
  <g-swiper v-if="swiper" ref="swiper" :centeredSlides="false" :loop="false">
    <template v-for="item in filteredItems">
      <slot v-bind="item" />
    </template>
  </g-swiper>
  <div :class="class" v-else>
    <template v-for="item in filteredItems">
      <slot v-bind="item" />
    </template>
  </div>
</template>

<script lang="ts">
import components from 'glooh-globals/src/components';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showFilter: {
      type: Boolean,
      default: true,
    },
    swiper: {
      type: Boolean,
      default: true,
    },
    class: {
      type: String,
      default: '',
    },
  },
  computed: {
    validItems(): any[] {
      const minDate = moment().startOf('day');
      const maxDate = moment().startOf('month').add(3, 'month');
      return this.items.filter((item: any) => {
        const endDate = item.date_end ? moment(item.date_end, 'YYYY-MM-DDThh:mm:ssZ').startOf('day') : undefined;
        const startDate = item.date_start ? moment(item.date_start, 'YYYY-MM-DDThh:mm:ssZ').startOf('day') : undefined;
        if (endDate && endDate.isBefore(minDate)) return false;
        if (!endDate && startDate && startDate.isBefore(minDate)) return false;
        if (startDate && startDate.isAfter(maxDate)) return false;
        return true;
      });
    },
    filteredItems(): any[] {
      if (this.$route.params.filter === 'all') return this.validItems;

      const minDate = moment().set('month', this.$route.params.filter).startOf('month');
      const maxDate = moment().set('month', this.$route.params.filter).endOf('month');

      return this.validItems.filter((item: any) => {
        const endDate = item.date_end ? moment(item.date_end, 'YYYY-MM-DDThh:mm:ssZ').startOf('day') : undefined;
        const startDate = item.date_start ? moment(item.date_start, 'YYYY-MM-DDThh:mm:ssZ').startOf('day') : undefined;
        if (endDate && endDate.isBefore(minDate)) return false;
        if (startDate && startDate.isAfter(maxDate)) return false;
        return true;
      });
    },
    months(): string[][] {
      const minDate = moment().startOf('month');

      const dates = this.validItems
        .map((i) => i.date_end ?? i.date_stat)
        .filter((i) => !!i)
        .map((d) => moment(d, 'YYYY-MM-DDThh:mm:ssZ').valueOf() / 1000);

      const maxDate = moment.unix(Math.max(...[...dates, minDate.valueOf() / 1000]));

      console.log('A:', minDate.format(), '-', maxDate.format(), '-', dates);
      return new Array(maxDate.diff(minDate, 'months') + 1).fill(1).map((v, i) => {
        const date = moment().startOf('month').add(i, 'month').toDate();
        return [this.$i18n.d(date, 'month'), this.$i18n.d(date, 'month', 'en-GB')];
      });
    },
    routes(): any[] {
      const routes = this.months.map((month: string[]) => ({
        params: { filter: month[1].replace('.', '') },
        text: month[0].toUpperCase().replace('.', ''),
      }));
      return [
        {
          params: { filter: 'all' },
          text: 'TOUS',
        },
        ...routes,
      ];
    },
  },
  watch: {
    items() {
      console.log('Items updated');
      this.$router.push({
        params: { filter: 'all' },
      });
    },
    filteredStores() {
      const component = this.$refs.swiper as any;
      component?.instance?.update();
    },
  },
});
</script>

<style lang="scss" scoped>
.filter {
  width: 80%;

  :deep(.nav-wrapper) {
    --button-padding: 1vmax 0;
    --navbar-anchor-background: transparent;
    --navbar-display: inline-flex;

    .navbar {
      border-bottom: 0.2vmax solid #ddd;
      justify-content: flex-start;
      font-weight: bold;
      font-size: 1.5vmax;
      gap: 2vmax;
    }

    .background {
      z-index: 1;
      &:after {
        content: ' ';

        position: absolute;
        left: 0;
        right: 0;
        top: calc(100%);
        height: 0.2vmax;
        background: var(--theme-color);
      }
    }
  }
}

.alphabet {
  width: 80%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin: 2.5vmax auto 1.5vmax;

  .char {
    display: inline-flex;
    font-size: 2vmax;
    line-height: 2vmax;
    font-weight: bold;
    color: #999;

    align-items: center;
    justify-content: center;

    width: 3vmax;
    height: 3vmax;

    &.inactive {
      pointer-events: none;
      opacity: 0.25;
    }

    &.active {
      pointer-events: all;
      color: #fff;
      background: var(--theme-color);
    }
  }
}

.swiper {
  --swiper-content-background: transparent;
  --swiper-caption-background: transparent;

  width: 100%;

  :deep(.swiper-container) {
    .swiper-slide {
      background: #f0f0f0;
      margin: 1vmax;
      transition: all 0.25s ease-in-out;

      .caption {
        color: black;
        bottom: 0;
      }

      &--image img {
        width: 40%;
        height: 40%;
        object-fit: contain;
      }
    }
  }
}
</style>
