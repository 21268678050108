export default {
  datetimeFormats: {
    date: {
      weekday: 'short',
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    },
    day: {
      day: '2-digit',
    },
    month: {
      month: 'short',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },

  messages: {
    back: 'Retour',
    navbar: {
      search: 'Buscar',
      home: 'Inicio',
      openings: 'Horarios',
      shops: 'Tiendas',
      restaurants: 'Restauración',
      services: 'Servicios',
      giftcard: 'Gift Card',
      agendas: 'Noticias y Eventos',
      newsletter: 'Newsletter',
      plan: 'Mapa',
      map: 'Mapa',
      camera: 'cámara',
      menu: 'Menú',
    },
    footer: {
      camera: 'Cámara',
      disabled: 'PMR',
      regular: 'grand affichage',
    },
    openings: {
      title: 'Nos horaires',
      specialDays: 'Jours spéciaux',
      closed: 'Fermé',
    },
    home: {
      leftPanel: {
        cta: {
          access: 'ACCÉDER',
          map: 'AU PLAN',
        },
      },
      news: 'Nuestras noticias y eventos',
      'news-sub': 'Descubrir todo',
      services: 'Nuestros servicios',
      'services-sub': 'Descubrir todo',
      restaurants: 'Nuestros restaurantes',
      'restaurants-sub': 'Toma una comida o una bebida.',
      shops: 'Nuestras tiendas',
      'shops-sub': 'Descubre todas las tiendas',
      seeAll: 'Todo',
      categoryDiscover: 'Descubrir',
    },
    search: {
      title: '¿Qué estás buscando?',
      shops: 'Tiendas',
      seeAll: 'Todo',
      learnMore: 'Más información',
      restaurants: 'Restaurantes',
      results: 'Resultados',
      noResults: 'No hay resultados',
      shopsAndRestaurants: 'Tiendas y restaurantes',
      services: 'Servicios',
      ourServices: 'Nuestros servicios',
      offers: 'Ofertas',
      ourOffers: 'Nuestras ofertas',
      noOffers: 'No hay oferta disponible por el momento',
      events: 'Eventos',
      ourEvents: 'Nuestros Eventos',
      showAll: 'Ver todo',
      promos: '1 promos',
      news: 'Noticias',
    },
    shops: {
      ourShopsAndRestaurants: 'Nuestras tiendas y restaurantes.',
      discover: 'Descubrir',
      ourShops: 'Tiendas y Restauración',
      ourRestaurants: 'Nos Restaurants',
      byDiscount: 'Por promoción',
      byCategory: 'Por categoria',
      byAll: 'Todo',
      search: 'Buscar',
      sorryNoDiscount: "Désolé, pas de promo aujourd'hui.",
      discountSingular: 'promo',
      discountPlural: 'promos',
      learnMore: 'Más información',
      noDealsAvailable: 'Aucune promotion disponible',
    },
    shop: {
      takeMeThere: 'Llévame',
      aboutThis: 'ACERCA DE',
      openings: 'Horario de aperturas',
    },
    services: {
      mostViewed: 'Les plus consultés',
      ourServices: 'Servicios',
      learnMore: 'Más información',
      availableHome: "Disponibles à l'accueil",
      otherServices: 'Autres services',
      others: 'Autres',
      toilets: 'Toilettes',
      lift: 'Ascenseur',
      infopoint: 'Informations',
      atm: 'Distributeur',
    },
    offers: {
      ourOffers: 'Nos Offres',
    },
    events: {
      title: 'Noticias',
      rightNow: 'En ce moment...',
      rightNowPart1: 'En ce',
      rightNowPart2: 'moment...',
      events: 'Événements',
      toCome: 'à venir',
      toComeCapitalize: 'À venir',
      discover: 'Découvrir',
      ourNews: 'Nos Actualités',
      ourEvents: 'Nos Événements',
    },
    map: {
      takeMe: 'Llévame',
      qrTheMap: 'El mapa en',
      qrOnYour: 'tu teléfono',
      myPosition: 'Ma Position',
      myDestination: 'Ma Destination',
      from: 'De',
      to: 'Para',
      navigation: {
        previous: 'Ant.',
        next: 'Prox.',
        cancel: 'Cancelar',
      },
      floor: 'Piso',
    },
    general: {
      learnMore: 'Más información',
      rightNow: 'En ce moment...',
    },
    eventCard: {
      news: 'Actualités',
    },
    pageFilterBar: {
      search: 'Recherche',
    },
    days: {
      mon: 'Lunes',
      tue: 'Martes',
      wed: 'Miércoles.',
      thu: 'Hoy',
      fri: 'Viernes',
      sat: 'Sábado',
      sun: 'Domingo',
    },
    categories: {
      services: {
        NosCartes: 'Nos Cartes',
        BesoinDe: 'Besoin de ...',
        ObjetsTrouves: 'Objets perdus',
        EnfantsEtNourissons: 'Enfants & Nourrissons',
        Divertissement: 'Divertissement',
        BornesInteractives: 'Bornes Interactives',
        PMR: 'PMR',
        toilette: 'Toilettes',
      },
    },
    screensaver: {
      main: 'Toca la pantalla',
      touch: '¡Toque la pantalla', //Tik op het scherm voor meer informatie!
      toKnowMore: 'para obtener más información!',
      cta: '¡Tiene lugar en tu centro!',
      sub: [
        'LES MAGASINS',
        'LES RESTAURANTS',
        'LES EVENEMENTS',
        "C'EST FACILE !",
        'OU SONT LES TOILETTES ?',
        'LES SERVICES',
        'LE PLAN DU CENTRE',
        'OU SONT LES TOILETTES?',
      ],
    },
    social: {
      followus: 'SUIVEZ-NOUS',
      onFB: 'SUR FACEBOOK',
      onIG: 'SUR INSTAGRAM',
      join: 'Rejoignez la communauté !!',
      scanQR: 'En scannant le QR code',
    },
    giftcard: {
      title: 'Un cadeau valable dans plus de 90 boutiques !',
      subtitle: 'Le meilleur des cadeaux !',
      body: "Vous ne savez pas quel cadeau offrir et redoutez de faire le mauvais choix? Vous avez peu de temps pour parcourir les boutiques? Alliez l'utile à l'agréable en optant pour une Gift Card. \n\nCette carte est valable pendant un an et peut être chargée pour un montant de votre choix entre 20€ et 250€. \nElle est de plus valable dans plus de 100 boutiques et restaurants participants.\n\nEn offrant la liberté de choisir, vous êtes sûr de faire des heureux autour de vous!",
      buyBtn: 'Acheter Ma Gift Card',
      balanceBtn: 'Consulter le solde de ma Gift Card',
      buyModal: {
        scanHere: 'Scannez le code QR ci-dessous pour acheter une gift card !',
        orBuy: 'En vente à The Point ou \nvia le webshop en scannant le QR Code',
      },
      balanceModal: {
        instructions:
          "Veuillez entrer le token unique de votre Gift Card composé de 9 chiffres qui se trouve à l'arrière.",
        send: 'Envoyer',
      },
    },
    newsletter: {
      title: 'Restez à jour',
      subtitle:
        'Abonnez-vous à la newsletter et restez informé des nouveautés, événements et offres exclusives du centre.',
      fields: {
        email: 'Email',
        firstName: 'Prenom',
        DOB: 'Date de naissance (optionnel)',
        family: 'Votre situation familliale (optionnel)',
        families: {
          select: 'Veuillez sélectionner...',
          house: 'Domicile familial',
          single: 'Célibataire, sans enfants',
          couple: 'En couple, sans enfants',
          kids: 'Célibataire ou en couple, avec enfants',
          kidsLeft: 'Mes enfants ne vivent plus à la maison',
        },
        postCode: 'Code Postal (optionnel)',
        iHaveRead: "J'ai lu et j'accepte la",
        theTCS: "Politique de confidentialité et les conditions générales d'utilisation",
        iAccept:
          "J'accepte que mon adresse e-mail et mes données personnelles soient traitées afin de m'envoyer des offres adaptées et des informations pertinentes par email",
        submit: 'Soumettre',
      },
    },
    cameraBar: {
      title: '¡Conviértete en la estrella, toma tu foto ahora! ',
    },
    camera: {
      title: 'Hazte una foto',
      subTitle: 'y compártela en nuestras redes sociales.',
      instructions: {
        step1: ' Tómate una foto',
        step2: ' Valida tu instantánea',
        step3: ' Reciba la foto por email',
        step4: {
          part1: 'Si su foto está validada,',
          part2: '¡se muestra en la pantalla grande!',
        },
        action: 'Sacar la foto',
      },
      confirmation: {
        title: '¡Tu foto acaba de ser enviada!',
        social_medias: 'No olvides compartirlo en las redes sociales.',
      },
      reception: {
        title: 'Recibe mi foto!',
        subTitle: '¡Ingrese su dirección de correo electrónico y reciba su foto!.',
      },
      cgu: 'He leído y acepto las condiciones generales de uso',
      newsletter: 'Deseo suscribirme a la newsletter',
      screen_diffusion: 'Quiero que mi foto se muestre en la pantalla gigante',
    },
  },
};
