<template>
  <div class="swiper" :class="classes">
    <div ref="container" class="swiper-container">
      <div class="swiper-wrapper">
        <slot>
          <g-slide>
          </g-slide>
        </slot>
      </div>
      <div v-if="pagination" ref="pagination" class="swiper-pagination"></div>
    </div>
    <div v-if="navigation" ref="prevEl" class="swiper-button-prev"></div>
    <div v-if="navigation" ref="nextEl" class="swiper-button-next"></div>
  </div>
</template>

<script lang="ts">
import Swiper, { Navigation, Pagination, Scrollbar } from 'swiper';
import { defineComponent } from 'vue';

Swiper.use([Navigation, Pagination, Scrollbar]);

export default defineComponent({
  props: {
    slidesPerView: {
      type: null,
      default: 'auto',
    },
    loop: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: false,
    },
    navigation: {
      type: Boolean,
      default: true,
    },
    autoHeight: {
      type: Boolean,
      default: false,
    },
    centeredSlides: {
      type: Boolean,
      default: true,
    },
    spaceBetween: {
      type: Number,
      default: 0,
    },
    slidesPerColumn: {
      type: Number,
      default: 0,
    },
    breakpoints: {
      type: Object,
      default: undefined,
    },
    fadeContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes(): object {
      return {
        'swiper--navigation': this.navigation,
        'swiper--fade-content': this.fadeContent,
      };
    },
  },
  data() {
    interface DataModel {
      instance: Swiper | undefined;
    }
    return {
      instance: undefined,
    } as DataModel;
  },
  mounted() {
    const { container, pagination, prevEl, nextEl } = this.$refs as {
      [key: string]: HTMLElement;
    };
    this.instance = new Swiper(container, {
      loop: this.loop,
      autoHeight: this.autoHeight,
      slidesPerView: this.slidesPerView,
      centeredSlides: this.centeredSlides,
      spaceBetween: this.spaceBetween,
      pagination: this.pagination && {
        el: pagination,
        clickable: true,
      },
      autoplay: {
        delay: 5000,
        disableOnInteraction: false,
      },
      navigation: this.navigation && { nextEl, prevEl },
      slidesPerColumn: this.slidesPerColumn,
      breakpoints: this.breakpoints,
    });
  },
});
</script>

<style lang="scss" scoped>
@import 'swiper/swiper-bundle.css';

.swiper {
  position: relative;
  overflow: hidden;
  margin: 0;

  &--navigation {
    padding: var(--swiper-navigation-padding);
  }

  &--fade-content {
    :deep(.swiper-slide) {
      &.swiper-slide-active {
        .swiper-slide--content {
          opacity: 1;
        }
      }
    }
    :deep(.swiper-slide--content) {
      opacity: 0;
      transition: opacity 0.25s ease-in-out 0.25s;
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    top: calc(50% - 2vmax);
    height: 4vmax;
    width: 3vmax;
    margin: 0;

    &:after {
      width: 2vmax;
      height: 2vmax;
      font-size: 2vmax;
      line-height: 2vmax;
      text-align: center;
      color: var(--swiper-arrow-color);
    }
  }

  .swiper-container {
    height: auto;
    overflow: hidden;
    margin: 0;

    .swiper-wrapper {
      align-items: center;
    }

    :deep(.swiper-pagination) {
      bottom: 1.5vmax;
      .swiper-pagination-bullet {
        background-color: var(--swiper-bullet-background);
        width: 2vmax;
        border-radius: 0;
        opacity: 1;

        &-active {
          background-color: var(--swiper-bullet-color);
          opacity: 1;
        }
      }
    }
  }
}
</style>
