import { App } from 'vue';

export function install(app: App, _options: any) {
  app.directive('vis', function (el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden';
  });

  app.directive('click-outside', {
    mounted(el, binding) {
      setTimeout(() => {
        const ourClickEventHandler = (event: MouseEvent | TouchEvent) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener('click', el.__vueClickEventHandler__);
      }, 0);
    },
    beforeUnmount(el) {
      document.removeEventListener('click', el.__vueClickEventHandler__);
    },
  });
}
