import { App } from 'vue';
import _ from 'lodash';

import cache from './misc/cache';

export function install(app: App, options: any) {
  console.log('Installing Mixins');

  if (_.get(options, 'mixins.cache.global', false)) {
    console.log('Installing Mixin, Cache');
    app.mixin(cache);
  }
}
