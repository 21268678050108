<template>
  <div class="page--socials">
    <div class="social insta">
      <g-icon-nav-insta />
      <h1>{{ $t('social.followus') }}</h1>
      <h4>{{ $t('social.onIG') }}</h4>
      <cimg :src="inQR" />
      <small>@{{ socials.instagram.replace(/.*\//, '') }}</small>
    </div>
    <div class="social fb">
      <g-icon-nav-fb />
      <h1>{{ $t('social.followus') }}</h1>
      <h4>{{ $t('social.onFB') }}</h4>
      <cimg :src="fbQR" />
      <small>@{{ socials.facebook.replace(/.*\//, '') }}</small>
    </div>
    <div class="logo"><cimg :src="`/${slug}/img/svg.png`" /></div>
    <div v-for="src of images" :key="src" class="image">
      <cimg :src="src" />
    </div>
    <div class="center">
      <h2>{{ $t('social.join') }}</h2>
      <span>{{ $t('social.scanQR') }}</span>
    </div>
    <g-button :to="{ name: 'home' }" class="close" icon="close" />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import axios from 'axios';
import QRCode from 'qrcode';

import project from 'glooh-globals/src/store/project';

export default defineComponent({
  setup() {

    const images = ref<string[]>([]);
    const fbQR = ref<string>();
    const inQR = ref<string>();

    onMounted(async () => {
      var Player = (window as any).Player;

      let url = project.config.socials.instagram_hub;
      if (typeof Player !== 'undefined') {
        url = (await Player.cachedURL(url, { forceUpdate: true }))?.cachedURI ?? url;
      }
      
      const { data: instagram } = await axios.get(url);

      images.value = await Promise.all((instagram ?? [])
      .slice(0, 7)
      .map(async (e: any) => {
        let url = "http://tools.glooh.media/ig-2021/proxy.php?url=" + encodeURIComponent(e.url);
        if (typeof Player !== 'undefined') {
          url = (await Player.cachedURL(url))?.cachedURI ?? url;
        }
        return url;
      }));

      fbQR.value = await QRCode.toDataURL(project.config.socials.facebook, {
        width: 512,
        margin: 1,
      });

      inQR.value = await QRCode.toDataURL(project.config.socials.instagram, {
        width: 512,
        margin: 1,
      });
    });

    return { socials: project.config.socials, images, inQR, fbQR, slug: import.meta.env.VITE_PROJECT_SLUG };
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--socials {
    display: grid;

    justify-content: center;
    align-items: center;

    grid-template-columns: repeat(5, calc((100% - 8rem) * 0.2));
    grid-template-rows: 38% 18% 38%;

    background: white;
    padding: 4rem;
    gap: 2rem;

    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: -95vw;
      width: 200vw;
      height: 200vh;
      background: rgba(var(--theme-color-rgb), 0.5);
      z-index: 0;

      transform: rotate(45deg);
    }

    > * {
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 1;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .close {
      position: absolute;
      bottom: 2rem;
      right: 2rem;

      width: 4rem;
      height: 4rem;
      color: white;
      background: black;

      :deep(svg) {
        width: 3.5rem;
        height: 3.5rem;
      }
    }

    .logo {
      position: absolute;
      bottom: 15rem;
      right: 8rem;
      width: 19rem;
      height: 20rem;
    }

    .center {
      grid-row: 2;
      grid-column: span 2;
      color: white;
      background: var(--theme-color);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;

      h2 {
        font-size: 3rem;
        line-height: 3rem;
        margin: 0;
        padding: 0;
      }

      span {
        font-size: 1.75rem;
        line-height: 1.75rem;
      }
    }

    .image:nth-child(1) {
      grid-column: 3;
      grid-row: 1;
    }

    .image:nth-child(2) {
      grid-column: 4;
      grid-row: 1;
    }

    .image:nth-child(3) {
      grid-column: 5;
      grid-row: 1;
    }

    .image:nth-child(4) {
      grid-column: 1;
      grid-row: 3;
    }

    .image:nth-child(5) {
      grid-column: 2;
      grid-row: 3;
    }

    .image:nth-child(6) {
      grid-column: 3;
      grid-row: 3;
    }

    .image:nth-child(7) {
      grid-column: 4;
      grid-row: 3;
    }

    .social {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      grid-row: 1 / 3;
      background: #222d33;
      color: white;

      padding: 1rem 0;

      h1,
      h4 {
        color: rgba(var(--theme-color-rgb), 0.8);
      }

      img {
        width: 60%;
        height: auto;
        margin: 1.5rem 0;
      }
    }
  }
}
</style>
