import { DateTime } from 'luxon';

type Hour = 0 | 1;

type AvailableHours = [
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
  Hour,
];

export type Dayparting = {
  mon: AvailableHours;
  tue: AvailableHours;
  wed: AvailableHours;
  thu: AvailableHours;
  fri: AvailableHours;
  sat: AvailableHours;
  sun: AvailableHours;
};

export function filterDayparting(dayParting: Dayparting) {
  // If no dayparting => always available
  if (!dayParting) return true;

  const daysLabel = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  const weekDay = daysLabel[DateTime.now().weekday] as keyof typeof dayParting;

  const today = DateTime.now().setZone('UTC+1');
  const hour = today.hour;

  const available = Boolean(dayParting?.[weekDay]?.[hour]);

  return !!available;
}
