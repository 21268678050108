import { i18n } from 'glooh-globals/src/i18n';

export default [
  {
    name: 'home',
    text: () => i18n()?.t('navbar.home'),
    icon: 'nav-home',
  },
  {
    name: 'search',
    text: () => i18n()?.t('navbar.search'),
    icon: 'nav-search',
    params: { result: 'all' },
  },
  {
    name: 'menu',
    text: () => i18n()?.t('navbar.menu'),
    icon: 'nav-menu',
  },
  {
    name: 'stores',
    text: () => i18n()?.t('navbar.shops'),
    icon: 'nav-stores',
    params: { type: 'shops', filter: 'all' },
  },
  {
    name: 'restaurants',
    text: () => i18n()?.t('navbar.restaurants'),
    icon: 'nav-restaurants',
    params: { type: 'restaurants' },
  },
  {
    name: 'services',
    text: () => i18n()?.t('navbar.services'),
    icon: 'nav-services',
    disabled: false,
  },
  {
    name: 'agendas',
    text: () => i18n()?.t('navbar.agendas'),
    icon: 'nav-agenda',
    params: { type: 'now', filter: 'all' },
  },
  {
    name: 'plan',
    text: () => i18n()?.t('navbar.plan'),
    icon: 'here',
  },
  {
    name: 'cam',
    text: () => i18n()?.t('navbar.camera'),
    icon: 'camera',
  },
  {
    name: 'horaires',
    text: i18n()?.t('navbar.openings'),
    icon: 'nav-time',
    disabled: false,
  },
  {
    name: 'giftcard',
    text: i18n()?.t('navbar.giftcard'),
    icon: 'nav-gift',
    disabled: false,
  },

  {
    name: 'newsletter',
    text: i18n()?.t('navbar.newsletter'),
    icon: 'nav-newsletter',
    disabled: false,
  },
];
