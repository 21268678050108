<template>
  <div class="logo-wrapper" :style="{ background: header ? `url(/${slug}/img/header.jpg)` : `rgb(${theme})` }">
    <cimg class="logo" :src="`/${slug}/img/${logo}`" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  setup() {
    return {
      header: project.config.header ?? true,
      logo: project.config.logo ?? 'logo.svg',
      slug: import.meta.env.VITE_PROJECT_SLUG,
      theme: import.meta.env.VITE_PROJECT_THEME
    };
  },
});
</script>

<style lang="scss" scoped>
.logo-wrapper {
  position: relative;
  overflow: hidden;
  width: 320px;
  height: 80%;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: ' ';
    background-color: rgba(0 0 0 / .6);
    z-index: 0;
  }

  .logo {
    position: relative;
    width: 320px;
    height: 100%;
    object-fit: contain;
    z-index: 10;
  }
}
</style>
