<template>
  <Content full :class="{ multiline: routes.length > 8 }">
    <div class="page--plans">
      <MapController ref="controller" />
      <div class="services" v-if="routes && routes.length > 0">
        <div class="service" v-for="(route, i) of routes" :key="i" @click="goToService(route.target_id)">
          <cimg :src="route.icon" />
          <span v-html="getLocaleValue(route.title)"></span>
        </div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue';
import MapController from '@/components/map/MapController.vue';
import Content from '@/components/layout/content/Content.vue';
import project from 'glooh-globals/src/store/project';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

export default defineComponent({
  components: {
    MapController,
    Content,
  },
  setup() {
    const controller = ref();

    const routes = computed(() => {
      const { id: parent_id } = project.menus.all().find((f: any) => f.key === 'map') ?? { id: -1 };
      return project.menus.findBy({ parent_id }) ?? [];
      //
      // return [{
      //   target_id: 44,
      //   icon: 'https://api.staging.avriopolis.gloohtest.co.uk/assets/dc4ff82a-5217-4afe-af8a-574a0817e265',
      //   title: 'Test'
      // }];
    });

    function goToService(serviceId: number) {
      if (controller.value) {
        const map = controller?.value?.map;
        map.setDestination(false, serviceId);
        //map.goToPlace(serviceId);
        //const place = map?.getPlaceForShop(serviceId);
        console.log('goToService:', serviceId, map.places);
      }
    }

    return {
      getLocaleValue,
      goToService,
      routes,
      controller,
    };
  },
});
</script>

<style lang="scss" scoped>
.multiline {
  :deep(.page-header) {
    justify-content: flex-start !important;
  }
  :deep(.page--plans) {
    height: calc(100% - 6rem);
    margin-top: 6rem;
  }
}

.page {
  .services {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;

    width: 100%;
    flex-wrap: wrap;
    padding: 1rem;

    background-color: black;

    .service {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      padding: 1rem;
      gap: 1rem;

      width: 8rem;
      height: 8rem;
      min-width: 8rem;
      min-height: 8rem;
      font-size: 0.6rem;
      text-transform: uppercase;

      background: var(--theme-color) 0% 0% no-repeat padding-box;
      color: white;

      cursor: pointer;

      span {
        font-size: 0.9rem;
        line-height: 0.9rem;
      }

      img {
        width: 4rem;
        height: 4rem;
        object-fit: contain;

        filter: brightness(0) invert(1);
      }
    }
  }

  &--plans {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    overflow: hidden;

    .map--controller {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
