/**
 * Layout: French
 */
export default {
  layout: {
    default: [
      'A Z E R T Y U I O P {bksp} -  7 8 9 ',
      'Q S D F G H J K L M @ & _  4 5 6 ',
      'W X C V B N {space} {enter} . #  1 2 3 0',
    ],
  },
};
