import Vue, { ComponentOptionsMixin, h } from 'vue';

export const Cache = {
  _config: {
    id: 'container',
    is: 'div',
  },
  store: {} as { [key: string]: any },
};

export default {
  data() {
    if (!this.$options.cache) return {};
    let isCacheString = typeof this.$options.cache === 'string';
    let config = isCacheString ? { id: this.$options.cache } : this.$options.cache;
    return {
      cache_config: { ...Cache._config, useCache: () => true, ...config },
      canCache: true,
    };
  },
  computed: {
    isCached() {
      return !!this.cached;
    },
    element() {
      if (!this.canCache) return {};
      return document.getElementById(this.cache_config.id);
    },
    cached() {
      if (!this.canCache || !this.cache_config.useCache()) return undefined;
      return Cache.store[this.cache_config.id];
    },
  },
  mounted() {
    if (!this.canCache) return;
    if (!this.isCached && this.$refs.uncached) {
      Cache.store[this.cache_config.id] = this.$refs.uncached;
    } else {
      let cachedRef = Cache.store[this.cache_config.id];
      if (cachedRef !== this.$refs.uncached) {
        if (this.$refs.uncached.parentNode) {
          this.$refs.uncached.parentNode.replaceChild(cachedRef, this.$refs.uncached);
        } else {
          this.element.replaceChild(cachedRef, this.element);
        }
      }
    }
  },
  render() {
    if (!this.canCache) return null;
    return h(this.cache_config.is, {
      id: this.cache_config.id,
      ref: 'uncached',
      class: this.cache_config.class,
    });
  },
} as ComponentOptionsMixin;
