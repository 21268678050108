<template>
  <component :is="'VisioMap'" ref="map" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import VisioMap from '@/components/map/VisioMap.vue';

export default defineComponent({
  components: {
    VisioMap,
  },
  setup(props, { emit }) {
    const map = ref();
    return {
      map,
    };
  },
});
</script>
