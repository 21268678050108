/**
 * Layout: English
 */
export default {
  layout: {
    default: [
      'Q W E R T Y U I O P {bksp} -  7 8 9 ',
      'A S D F G H J K L @ & _ #  4 5 6 ',
      'Z X C V B N M {space} {enter} .  1 2 3 0',
    ],
  },
};
