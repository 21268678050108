<template>
  <div class="footer-nav">
    <nav class="">
      <g-navbar param="type" :routes="currentRoutes" />
    </nav>
  </div>
</template>

<script setup lang="ts">
import routes from '@/assets/routes';
import project from 'glooh-globals/src/store/project';

const menuConfig = project.config?.main_menu ?? ['home', 'search', 'stores', 'services', 'plan'];

const currentRoutes = routes.filter((route) => menuConfig.includes(route.name));
</script>

<style lang="scss" scoped>
.footer-nav {
  --navbar-anchor-background: transparent;
  --navbar-display: inline-flex;
  --navbar-anchor-foreground: black;
  --navbar-anchor-foreground-active: var(--theme-color);
  --button-background-active: transparent;
  --button-white-space: wrap;
  --button-direction: column;
  --button-padding: 0;
  padding: 10px;

  flex: 1;
  display: flex;
  flex-direction: column;

  .extra {
    display: flex;
    flex-direction: row;
    gap: 2rem;

    div {
      display: flex;
      flex: 1;
    }

    button,
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: normal;

      flex: 1;
      height: 5rem;
      border: none;
      background: var(--theme-color);
      color: white;
      gap: 1rem;

      font-size: 1.5rem;
      line-height: 1.5rem;
      text-transform: uppercase;
      font-weight: normal;

      cursor: pointer;

      &.socials {
        background: #222d33;
      }

      img,
      svg {
        height: 2rem;
        filter: brightness(0) invert(1);
      }
    }
  }

  :deep(.nav-wrapper) {
    .navbar {
      width: auto;
      //max-width: 80vw;
      height: 100%;
      overflow: hidden;
      display: flex;

      .nav-item,
      .g-btn {
        height: 125px !important;
        max-width: 300px !important;
        justify-content: center;
        padding: 0;
      }
    }
  }

  .footer {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;

    .nav-wrapper {
      height: 4.5rem;
      transition: height 0.25s ease-in-out;

      background: white;
      z-index: 1;

      :deep(.nav-item:first-child) {
        --button-direction: row-reverse;
        a svg {
          transition: transform 0.25s ease-in-out;
        }
      }

      &.visible {
        height: 24rem;

        :deep(.nav-item:first-child) {
          --button-direction: row-reverse;
          a {
            color: var(--navbar-anchor-foreground-active);
            svg {
              transform: rotate(90deg);
            }
          }
        }
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding: 2rem;

      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .map-link {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;

        height: 130px;
        background: #efefef;
        color: black;

        font-size: 2rem;

        background: #efefef url('/#{$projectSlug}/img/map-preview.png') no-repeat;
        background-size: cover;
        background-position: center center;
        filter: grayscale(0.5);

        svg,
        img {
          height: 3rem;
        }
      }
    }
  }
}
</style>
