import { App } from 'vue';
import _ from 'lodash';

import timeout from './misc/timeout';
import player from './misc/player';

export function install(app: App, options: any) {
  console.log('Installing Plugins');

  if (_.get(options, 'plugins.timeout.enabled', true)) {
    console.log('Installing Plugin, Timeout');
    app.use(timeout, _.get(options, 'plugins.timeout', {}));
  }

  if (_.get(options, 'plugins.player.enabled', true)) {
    console.log('Installing Plugin, Player');
    app.use(player, _.get(options, 'plugins.player', {}));
  }
}
