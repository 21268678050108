import { App, reactive } from 'vue';
import _ from 'lodash';
import shortid from 'shortid';

import { useAnalytics } from './analytics';
import { getKioskID } from '../../store/project';

function createTimeout(options: any) {
  const { track, reset, identify } = useAnalytics();
  return {
    state: reactive({
      timeout: options.timeout || 30900,
      warning: options.warning || 5000,
      time: 0,
      interval: undefined as any,
      paused: false,
      timedOut: true,
      wasTimedOut: 500,
      components: [] as any[],
    }),

    init() {
      document.onmousedown = () => this.reset();
      document.onmouseup = () => this.reset();
      document.ontouchstart = () => this.reset();
      document.ontouchend = () => this.reset();
      document.onclick = () => this.reset();
      document.onkeypress = () => this.reset();
      document.addEventListener('scroll', () => this.reset(), true);
    },

    tick() {
      const isPaused = this.shouldPause();

      if (this.state.paused !== isPaused) {
        this.state.paused = isPaused;
        if (this.state.paused) {
          if (options.verbose) console.log('[T] Paused');
          return;
        } else {
          if (options.verbose) console.log('[T] Unpaused');
          this.reset();
        }
      }

      if (!this.state.timedOut && !this.state.paused) {
        this.state.time -= 100;

        if (this.state.wasTimedOut > 0) {
          this.state.wasTimedOut -= 100;
        }

        if (options.verbose) console.log('[T]', this.remaining() + 's');

        if (this.state.time === this.state.warning) {
          this.triggerWarning();
        }

        if (this.state.time === 0) {
          this.triggerTimeout();
        }
      }
    },

    reset: _.throttle(function (this: any) {
      if (this.state.time < this.state.warning && this.state.time > 0) {
        track('timeout.disabled');
        if (options.verbose) console.log('[T] Countdown Cancelled!');
      } else {
        if (options.verbose) console.log('[T] Reset!');

        if (this.state.timedOut) {
          const kioskId = getKioskID();
          identify(shortid.generate(), {
            kiosk: kioskId,
            event: 'session',
            content: 'session_start',
          });
        }
      }

      clearInterval(this.state.interval);
      this.state.timedOut = false;
      this.state.time = this.state.timeout;
      this.state.interval = setInterval(() => this.tick(), 100);
    }, 1000),

    remaining() {
      return Math.ceil(this.state.time / 1000).toFixed(2);
    },

    shouldPause() {
      for (let component of this.state.components) {
        const ct = component.$options.timeout;
        const canTimeout = component.canTimeout;
        if ((ct !== undefined && !ct) || (canTimeout && !canTimeout(component))) {
          return true;
        }
      }
      return false;
    },

    triggerWarning() {
      if (options.verbose) console.log('[T] Countdown Started!');
      track('timeout.started');

      for (let component of this.state.components) {
        if (component.onTimeoutWarning) {
          component.onTimeoutWarning();
        }
      }
    },

    triggerTimeout() {
      if (options.verbose) console.log('[T] Timed out!');
      track('timeout.finished');
      reset();

      this.state.timedOut = true;
      this.state.wasTimedOut = 500;

      for (let component of this.state.components) {
        if (component.onTimeout) {
          component.onTimeout();
        }
      }
    },

    register(component: any) {
      this.state.components.push(component);
    },

    remove(component: any) {
      let index = this.state.components.indexOf(component);
      if (index >= 0) this.state.components.splice(index, 1);
    },
  };
}

export default {
  install: (app: App, options: any) => {
    const timeout = createTimeout(options);
    timeout.init();

    app.mixin({
      data: function () {
        return {
          timeout,
        };
      },
      computed: {
        isTimeoutSupported(): boolean {
          return this.onTimeoutWarning || this.onTimeout || this.canTimeout || this.$options.timeout !== undefined;
        },
        isTimedOut(): boolean {
          return this.timeout.state.timedOut;
        },
        wasTimedOut(): boolean {
          return this.timeout.state.wasTimedOut > 0;
        },
      },
      mounted: function () {
        if (this.isTimeoutSupported) {
          if (options.verbose) console.log('[T] Registered!', this);
          this.timeout.register(this);
        }
      },
      beforeUnmount: function () {
        if (this.isTimeoutSupported) {
          this.timeout.remove(this);
        }
      },
    });
  },
};
