<template>
  <img data-cached="true" :src="cachedSrc" />
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { getImage } from '@/store/cache';

export default defineComponent({
  props: {
    src: String,
  },
  setup(props) {
    return {
      cachedSrc: computed(() => {
        return getImage(props.src);
      }),
    };
  },
});
</script>
