<template>
  <div v-if="slides.length > 0" class="carousel">
    <div ref="images" class="images">
      <template v-if="loaded && computedSlides.length > 0">
        <cimg v-for="(slide, i) in computedSlides" :key="i" class="img-wrapper" :src="slide.image" :style="style(i)" />
      </template>
      <g-icon-loading v-else />
    </div>
    <div class="captions">
      <transition enter-active-class="animated slideInRight" leave-active-class="animated slideOutLeft">
        <div class="caption" :key="`${activeIndex}-caption`">
          <p>{{ computedSlides[activeIndex]?.caption }}</p>
        </div>
      </transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

import _ from 'lodash';

type Slide = {
  image: string;
  caption: string;
  width?: number;
  height?: number;
};

export default defineComponent({
  props: {
    slides: {
      type: Array as PropType<Array<Slide>>,
      default: [],
    },
    delay: {
      type: Number,
      default: 5000,
    },
  },
  data: () => ({
    computedSlides: [] as Slide[],
    interval: undefined as NodeJS.Timeout | undefined,
    index: 0,
    loaded: false,

    observer: undefined as ResizeObserver | undefined,
    imagesWidth: 100,
    imagesHeight: 100,
  }),
  computed: {
    activeIndex(): number {
      return this.index % this.computedSlides.length;
    },
    positions(): number[][] {
      return [-3, -2, -1, 0, 1, 2, 3].map((o, i) => [i, o, this.slideIndex(o)]);
    },
  },
  wathch: {
    slides() {
      this.updateSlides();
    },
    delay() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = setInterval(() => this.index++, this.delay);
    },
  },
  methods: {
    slideIndex(offset: number): number {
      return (this.activeIndex + offset + this.computedSlides.length) % this.computedSlides.length;
    },
    slideXPos(activeX: number, position: number): string {
      let currentX = activeX,
        spacers = 0,
        scale = position === 0 ? 1 : 0.5;
      for (let i = position; i < 0; i++) {
        let si = this.slideIndex(i);
        currentX -= this.getScaledWidth(si, this.imagesHeight * scale);
        spacers--;
      }
      for (let i = 0; i < position; i++) {
        let si = this.slideIndex(i);
        currentX += this.getScaledWidth(si, this.imagesHeight * (i == 0 ? 1 : scale));
        spacers++;
      }
      return `calc(${currentX}px + (${spacers} * 2vmax))`;
    },
    style(index: number) {
      let active = this.activeIndex === index,
        scale = active ? 1 : 0.5;
      let activeWidth = this.getScaledWidth(this.activeIndex, this.imagesHeight),
        activeX = (this.imagesWidth - activeWidth) / 2;
      let currentWidth = this.getScaledWidth(index, this.imagesHeight * scale);

      let position = (this.positions.find(([i, o, s]) => s === index) || [])[1];
      if (position === undefined) return { display: 'none' };

      return {
        left: active ? `${activeX}px` : this.slideXPos(activeX, position),
        width: currentWidth + 'px',
        opacity: position === 3 ? 0 : 1,
        zIndex: position === 3 ? -1 : 1,
      };
    },
    getScaledWidth(index: number, targetH: number) {
      const { width, height } = this.computedSlides[index];
      return Math.min((targetH / (height || 1)) * (width || 1), this.imagesWidth);
    },
    getDimensions(image: string): Promise<{ width: number; height: number }> {
      return new Promise((res) => {
        const img = new Image();
        const timeout = setTimeout(() => res({ width: 0, height: 0 }), 2000);
        img.onload = () => {
          clearTimeout(timeout);
          res({ width: img.width, height: img.height });
        };
        img.src = image;
      });
    },
    updateSlides: _.debounce(async function (this: any) {
      try {
this.loaded = false;
      clearInterval(this.interval);

      for (let [index, slide] of this.slides.entries()) {
        let { width, height } = await this.getDimensions(slide.image);
        this.slides[index].width = width;
        this.slides[index].height = height;
      }

      if (this.slides.length > 0 && this.slides.length < 7) {
        this.computedSlides = [];
        for (let i = 0; i < Math.ceil(7 / this.slides.length); i++) this.computedSlides.push(...this.slides);
      } else {
        this.computedSlides = this.slides;
      }

      this.computedSlides = this.computedSlides.filter(
        (slide: Slide) => (slide.width || 0) > 0 && (slide.height || 0) > 0,
      );

      this.loaded = true;
      this.index = this.computedSlides.length;
      this.interval = setInterval(() => this.index++, this.delay);
      } catch (e) {
        console.log(e)
      }
    }, 100),
  },
  mounted() {
    if (this.slides.length > 0) {
      this.updateSlides();

      this.observer = new ResizeObserver(() => {
        let { width, height } = (this.$refs.images as HTMLElement).getBoundingClientRect();
        this.imagesWidth = width;
        this.imagesHeight = height;
      });

      this.observer.observe(this.$refs.images as Element);
    }
  },
  beforeUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  },
});
</script>

<style lang="scss" scoped>
.carousel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  background: var(--carousel-background);
  border: var(--carousel-border);

  .images {
    position: relative;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    flex: 1;
    margin: var(--carousel-image-spacing) 0;
    overflow: hidden;

    .img-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      object-fit: contain;

      transition: all 1s linear;
    }
  }

  .captions {
    position: relative;
    width: 100%;
    height: calc(1vmax + (var(--carousel-caption-lines) * var(--carousel-caption-font-size)));
    min-height: var(--carousel-caption-min-height);

    .caption {
      position: absolute;
      top: 0;
      left: var(--carousel-caption-padding);
      bottom: 0;
      right: var(--carousel-caption-padding);

      display: flex;
      justify-content: center;
      align-items: center;

      p {
        font-size: var(--carousel-caption-font-size);
        line-height: var(--carousel-caption-font-size);

        display: -webkit-box;
        white-space: break-spaces;
        -webkit-line-clamp: var(--carousel-caption-lines);
        -webkit-box-orient: vertical;
        overflow: hidden;

        color: var(--carousel-caption-color);
      }
    }
  }
}
</style>
