export default {
  datetimeFormats: {
    date: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    day: {
      day: 'numeric',
    },
    month: {
      month: 'short',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },

  messages: {
    navbar: {
      search: 'Search',
      home: 'Home',
      openings: 'Hours',
      shops: 'Shops',
      restaurants: 'Restaurants',
      services: 'Services',
      giftcard: 'Gift Card',
      agendas: 'News & Events',
      newsletter: 'Newsletter',
      map: 'Map',
    },
    footer: {
      camera: 'Camera',
      disabled: 'Disabled',
    },
    home: {
      news: 'Upcoming events',
      'news-sub': 'Discover all the upcoming events',
      services: 'Our services',
      'services-sub': '& FACILITES',
      restaurants: 'Our restaurants',
      'restaurants-sub': 'Where to eat at Mon Grand Plaisir',
      shops: 'Our Shops',
      'shops-sub': 'Discover our shops',
      seeAll: 'See All',
      categoryDiscover: 'Discover',
    },
    search: {
      lookingFor: 'What are you looking for ?',
      results: 'Results',
      noResults: 'No results',
      shopsAndRestaurants: 'Shops et Restaurants',
      services: 'Services',
      ourServices: 'Our Services',
      offers: 'Deals',
      ourOffers: 'Our Deals',
      noOffers: 'No offers available at the moment',
      events: 'Events',
      ourEvents: 'Our Events',
      showAll: 'Show All',
      promos: '1 deals',
      learnMore: 'Learn More',
      news: 'News',
      seeAll: 'See all',
      shops: 'Shops',
    },
    shops: {
      ourShopsAndRestaurants: 'Our Shops & Restaurants',
      discover: 'Discover',
      ourShops: 'Our Shops',
      ourRestaurants: 'Our Restaurants',
      byDiscount: 'By Discount',
      byCategory: 'By Category',
      byAll: 'All',
      search: 'Search',
      sorryNoDiscount: 'Sorry, no deals for today',
      discountSingular: 'deal',
      discountPlural: 'deals',
      learnMore: 'Learn more',
      noDealsAvailable: 'No Deals available at this time',
    },
    shop: {
      takeMeThere: 'Take me there !',
      aboutThis: 'ABOUT',
      openings: 'Opening hours',
    },
    services: {
      mostViewed: 'Most Viewed',
      ourServices: 'Our Services',
      learnMore: 'Learn more',
      availableHome: 'available at helpdesk',
      otherServices: 'Other services',
      others: 'Others',
    },
    offers: {
      ourOffers: 'Our Deals',
    },
    events: {
      title: 'Our events & news',
      rightNowPart1: 'Right',
      rightNowPart2: 'now...',
      events: 'Events',
      toCome: 'upcoming',
      toComeCapitalize: 'Upcoming',
      discover: 'Discover',
      ourNews: 'Our News',
      ourEvents: 'Our events',
    },
    map: {
      takeMe: 'Take me there!',
      qrTheMap: 'The map',
      qrOnYour: 'on your phone',
      aboutThis: 'About',
      myPosition: 'My Position',
      myDestination: 'My Destination',
      from: 'From',
      to: 'To',
      navigation: {
        previous: 'Previous',
        next: 'Next',
        cancel: 'Cancel',
      },
      floor: 'Floor',
    },
    general: {
      learnMore: 'Learn more',
      rightNow: 'Happening now...',
    },
    eventCard: {
      news: 'News',
    },
    pageFilterBar: {
      search: 'Search',
    },
    days: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday',
    },
    categories: {
      services: {
        NosCartes: 'Our Cards',
        BesoinDe: 'Useful services',
        ObjetsTrouves: 'Lost & Found',
        EnfantsEtNourissons: 'Children & Babies',
        Divertissement: 'Entertainment',
        BornesInteractives: 'Interactive kiosk',
        PMR: 'Disabled',
        toilette: 'Toilets',
      },
    },
    screensaver: {
      main: 'TOUCH THE SCREEN',
      sub: ['THE SHOPS', 'THE RESTAURANTS', 'THE EVENTS', "IT'S EASY", 'THE SERVICES', "THE CENTRE'S MAP"],
    },
  },
};
