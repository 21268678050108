<template>
  <div class="event-card">
    <g-button :to="to" :background="getImage((event?.images ?? [])[0])">
      {{ getLocaleValue(event?.title) }}
    </g-button>
    <div v-if="(start && end) || action" class="footer">
      <div v-if="start && end" class="dates">
        <p>
          <b>{{ $d(start, 'day') }}</b>
          {{ $d(start, 'month') }} -
          <b>{{ $d(end, 'day') }}</b>
          {{ $d(end, 'month') }}
        </p>
      </div>
      <g-button v-if="action" class="action" icon="arrow-right" @click="$emit('action')">
        {{ action }}
      </g-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import { getImage } from '@/store/cache';

export default defineComponent({
  props: {
    event: {
      type: Object,
      default: undefined,
    },
    to: {
      type: Object,
      default: undefined,
    },
    action: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    start(): Date | undefined {
      if (!this?.event?.date_start) return undefined;
      const date = moment(this.event?.date_start, 'YYYY-MM-DDThh:mm:ssZ');
      return date.isValid() ? date.toDate() : undefined;
    },
    end(): Date | undefined {
      if (!this?.event?.date_end) return undefined;
      const date = moment(this.event?.date_end, 'YYYY-MM-DDThh:mm:ssZ');
      return date.isValid() ? date.toDate() : undefined;
    },
  },
  methods: {
    getLocaleValue,
    getImage
  },
});
</script>

<style lang="scss" scoped>
.event-card {
  --button-direction: column;

  display: flex;
  flex-direction: column;

  width: 25vmax;
  height: 13vmax;
  min-width: 25vmax;
  min-height: 13vmax;
  overflow: hidden;

  > .g-btn {
    flex: 1;
    justify-content: center;

    :deep(span) {
      width: 80%;
      white-space: initial;
    }
  }

  .footer {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ededed;
    min-height: 3vmax;

    .dates {
      flex: 1;
      text-transform: uppercase;
    }
  }

  .action {
    --button-padding: 1vmax;
    --button-direction: row-reverse;

    font-size: 0.85vmax;
    background: var(--theme-color);
    color: white;
  }
}
</style>
