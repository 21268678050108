<template>
  <Content full>
    <template #header>
      <h1>{{ $t('services.ourServices') }}</h1>
    </template>
    <div class="page--services">
      <div class="services-wrapper">
        <div class="list">
          <g-service-card v-for="(service, i) in services" :key="i" :service="service" />
        </div>
      </div>
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import Content from '@/components/layout/content/Content.vue';

import project from 'glooh-globals/src/store/project';

export default defineComponent({
  components: {
    Content,
  },
  computed: {
    services() {
      return project.services.all();
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--services {
    display: flex;
    flex-direction: row;
    gap: 2vmax;
    background: white;
    flex: 1;
    overflow: hidden;

    .services-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;

      flex: 1;
      gap: 1vmax;
      overflow: auto;

      :deep(.title-wrapper) {
        --title-line-margin: 0;
        --title-font-size: 1.5vmax;
        width: 100%;

        .title {
          text-align: left;
        }
        .subtitle {
          margin-top: 0.5vmax;
        }
      }

      .list {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1vmax;
        flex: 1;
        padding: 2rem;
      }
    }

    .map-preview {
      width: 35rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
</style>
