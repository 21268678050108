<template>
  <Content modal>
    <template #header>
      <cimg :src="store.logo" />
    </template>
    <div class="page--store">
      <div class="categories">
        {{ store.categories?.slice(0, 2).join(', ') }}
      </div>
      <hr style="width: 100%" />
      <div class="left">
        <div class="store-content">
          <router-link class="takeMeThere" :to="{ name: 'plan', params: { placeId: store.id } }">
            <icon-here />
            {{ $t('shop.takeMeThere') }}
          </router-link>
          <div class="desc">
            <div v-html="store['opening_hours-text'] ? store['opening_hours-text'] : ''"></div>
            <div class="categories">
              {{ $t('shop.aboutThis') }} {{ getLocaleValue(store.title) }}
              <br />
            </div>

            <div class="desc-content" v-html="store?.description_long ?? store?.descriptionv_short"></div>
            <br />
            <h6>{{ store?.phone == 'null' ? '' : store?.phone }}</h6>
            <h6>{{ store?.website == 'null' ? '' : store?.website }}</h6>
          </div>
        </div>
      </div>
      <hr style="width: 100%" />
    </div>
  </Content>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import _ from 'lodash';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import MapProvider from '@/components/map/MapProvider.vue';
import project from 'glooh-globals/src/store/project';
import Content from '@/components/layout/content/Content.vue';
import IconHere from '@/components/icons/IconHere.vue';

export default defineComponent({
  components: {
    MapProvider,
    Content,
    IconHere,
  },
  props: {
    id: {
      type: Number,
    },
  },
  computed: {
    storeId(): number {
      return this.id || parseInt(this.$route.params.id as string);
    },
    store(): any {
      return project.shops.find(this.storeId);
    },
    weekdays() {
      return ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
    },
    weekday() {
      return moment().locale('en').format('dddd').toLowerCase();
    },
  },
  methods: {
    openHoursThisDay(day: string) {
      const days = this.store.opening_hours.filter((v) => v.dayOfWeek.includes(day));
      if (days.length === 0) return [this.$t('openings.closed')];
      return days.map((c) => `${c.opens} - ${c.closes}`);
    },
    getLocaleValue,
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--store {
    display: flex;
    flex-direction: row;
    gap: 1vmax;

    flex: 1;
    flex-direction: column;
    width: 100%;
    font-size: 1.5vmax;
    line-height: 1.5vmax;
    color: black;
    background: white;
    overflow: visible;
    height: 100%;
    padding: 2rem;

    .takeMeThere {
      background: #222d33;
      border: none;
      padding: 1.25rem 3rem;
      color: white;

      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      font-size: 1.15rem;

      span {
        font-size: 2rem;
      }

      svg {
        height: 2rem;
      }
    }

    .left,
    .right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      font-size: 1.15rem;
      gap: 3rem;
      overflow: auto;
      height: 100%;
      text-align: left;
    }

    .desc-content {
      margin-top: 16px;
    }

    .categories {
      margin-top: 16px;
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      text-transform: uppercase;
    }

    .store-content {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: center;
      gap: 4rem;
      flex: 1;
      width: 100%;

      a {
        white-space: nowrap;
        background-color: var(--theme-color);
      }
    }
  }
}
</style>
