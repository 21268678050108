export default {
  datetimeFormats: {
    date: {
      weekday: 'long',
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    },
    day: {
      day: '2-digit',
    },
    month: {
      month: 'short',
    },
    time: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    },
  },

  messages: {
    back: 'Retour',
    navbar: {
      search: 'Recherche',
      home: 'Accueil',
      menu: 'Menu',
      openings: 'Horaires',
      shops: 'Boutiques',
      restaurants: 'Restaurants',
      services: 'Services',
      giftcard: 'Gift Card',
      agendas: 'News & Events',
      newsletter: 'Newsletter',
      map: 'Plan',
      plan: 'Plan',
      camera: 'Caméra',
    },
    footer: {
      camera: 'Camera',
      disabled: 'PMR',
      regular: 'grand affichage',
    },
    openings: {
      title: 'Nos horaires',
      specialDays: 'Jours spéciaux',
      closed: 'Fermé',
    },
    home: {
      leftPanel: {
        cta: {
          access: 'ACCÉDER',
          map: 'AU PLAN',
        },
      },
      news: 'Nos news et events',
      'news-sub': 'DECOUVRIR TOUS LES EVENEMENTS',
      services: 'Nos services',
      'services-sub': 'LES FACILITES',
      restaurants: 'Nos restaurants',
      'restaurants-sub': 'SE RESTAURER OU BOIRE UN CAFÉ',
      shops: 'Nos boutiques',
      'shops-sub': 'DECOUVRIR TOUTES NOS BOUTIQUES',
      seeAll: 'Tout voir',
      categoryDiscover: 'Découvrez',
    },
    search: {
      title: 'Que cherchez-vous ?',
      shops: 'Boutiques',
      seeAll: 'Tout voir',
      learnMore: 'En savoir plus',

      restaurants: 'Restaurants',
      results: 'Résultats',
      noResults: 'Pas de Résultats',
      shopsAndRestaurants: 'Boutiques et Restaurants',
      services: 'Services',
      ourServices: 'Nos Services',
      offers: 'Offres',
      ourOffers: 'Nos Offres',
      noOffers: 'Aucune offre disponible pour le moment',
      events: 'Événements',
      ourEvents: 'Nos Événements',
      showAll: 'Tout Afficher',
      promos: '1 promos',
      news: 'Actualités',
    },
    shops: {
      ourShopsAndRestaurants: 'Nos Boutiques & Restaurants',
      discover: 'Découvrir',
      ourShops: 'Nos Boutiques',
      ourRestaurants: 'Nos Restaurants',
      byDiscount: 'Par Promotion',
      byCategory: 'Par Catégorie',
      byAll: 'Tous',
      search: 'Recherche',
      sorryNoDiscount: "Désolé, pas de promo aujourd'hui.",
      discountSingular: 'promo',
      discountPlural: 'promos',
      learnMore: 'En savoir plus',
      noDealsAvailable: 'Aucune promotion disponible',
    },
    shop: {
      takeMeThere: "S'Y RENDRE",
      aboutThis: 'À propos de',
      openings: "Heures d'ouverture",
    },
    services: {
      mostViewed: 'Les plus consultés',
      ourServices: 'Nos Services',
      learnMore: 'En savoir plus',
      availableHome: "Disponibles à l'accueil",
      otherServices: 'Autres services',
      others: 'Autres',
      toilets: 'Toilettes',
      lift: 'Ascenseur',
      infopoint: 'Informations',
      atm: 'Distributeur',
    },
    offers: {
      ourOffers: 'Nos Offres',
    },
    events: {
      title: 'Nos événements & actualités',
      rightNow: 'En ce moment...',
      rightNowPart1: 'En ce',
      rightNowPart2: 'moment...',
      events: 'Événements',
      toCome: 'à venir',
      toComeCapitalize: 'À venir',
      discover: 'Découvrir',
      ourNews: 'Nos Actualités',
      ourEvents: 'Nos Événements',
    },
    map: {
      takeMe: "M'Y EMMENER",
      qrTheMap: 'La carte sur',
      qrOnYour: 'votre téléphone',
      myPosition: 'Ma Position',
      myDestination: 'Ma Destination',
      from: 'De',
      to: 'À',
      navigation: {
        previous: 'Préc.',
        next: 'Suiv.',
        cancel: 'Annuler',
      },
      floor: 'Niveau',
    },
    general: {
      learnMore: 'En savoir plus',
      rightNow: 'En ce moment...',
    },
    eventCard: {
      news: 'Actualités',
    },
    pageFilterBar: {
      search: 'Recherche',
    },
    days: {
      mon: 'Lundi',
      tue: 'Mardi',
      wed: 'Mercredi',
      thu: 'Jeudi',
      fri: 'Vendredi',
      sat: 'Samedi',
      sun: 'Dimanche',
    },
    categories: {
      services: {
        NosCartes: 'Nos Cartes',
        BesoinDe: 'Besoin de ...',
        ObjetsTrouves: 'Objets perdus',
        EnfantsEtNourissons: 'Enfants & Nourrissons',
        Divertissement: 'Divertissement',
        BornesInteractives: 'Bornes Interactives',
        PMR: 'PMR',
        toilette: 'Toilettes',
      },
    },
    screensaver: {
      main: 'TOUCHEZ L’ECRAN',
      touch: "TOUCHEZ L'ECRAN", //Tik op het scherm voor meer informatie!
      cta: 'Ça se passe dans votre centre !',
      ctaNoSocials: 'Découvrez notre centre !',
      toKnowMore: 'pour en savoir plus !',
      sub: [
        'LES MAGASINS',
        'LES RESTAURANTS',
        'LES EVENEMENTS',
        "C'EST FACILE !",
        'OU SONT LES TOILETTES ?',
        'LES SERVICES',
        'LE PLAN DU CENTRE',
        'OU SONT LES TOILETTES?',
      ],
    },
    social: {
      followus: 'SUIVEZ-NOUS',
      onFB: 'SUR FACEBOOK',
      onIG: 'SUR INSTAGRAM',
      join: 'Rejoignez la communauté !!',
      scanQR: 'En scannant le QR code',
    },
    giftcard: {
      title: 'Un cadeau valable dans plus de 90 boutiques !',
      subtitle: 'Le meilleur des cadeaux !',
      body: "Vous ne savez pas quel cadeau offrir et redoutez de faire le mauvais choix? Vous avez peu de temps pour parcourir les boutiques? Alliez l'utile à l'agréable en optant pour une Gift Card. \n\nCette carte est valable pendant un an et peut être chargée pour un montant de votre choix entre 20€ et 250€. \nElle est de plus valable dans plus de 100 boutiques et restaurants participants.\n\nEn offrant la liberté de choisir, vous êtes sûr de faire des heureux autour de vous!",
      buyBtn: 'Acheter Ma Gift Card',
      balanceBtn: 'Consulter le solde de ma Gift Card',
      buyModal: {
        scanHere: 'Scannez le code QR ci-dessous pour acheter une gift card !',
        orBuy: 'En vente à The Point ou \nvia le webshop en scannant le QR Code',
      },
      balanceModal: {
        instructions:
          "Veuillez entrer le token unique de votre Gift Card composé de 9 chiffres qui se trouve à l'arrière.",
        send: 'Envoyer',
      },
    },
    newsletter: {
      title: 'Restez à jour',
      subtitle:
        'Abonnez-vous à la newsletter et restez informé des nouveautés, événements et offres exclusives du centre.',
      fields: {
        email: 'Email',
        firstName: 'Prenom',
        DOB: 'Date de naissance (optionnel)',
        family: 'Votre situation familliale (optionnel)',
        families: {
          select: 'Veuillez sélectionner...',
          house: 'Domicile familial',
          single: 'Célibataire, sans enfants',
          couple: 'En couple, sans enfants',
          kids: 'Célibataire ou en couple, avec enfants',
          kidsLeft: 'Mes enfants ne vivent plus à la maison',
        },
        postCode: 'Code Postal (optionnel)',
        iHaveRead: "J'ai lu et j'accepte la",
        theTCS: "Politique de confidentialité et les conditions générales d'utilisation",
        iAccept:
          "J'accepte que mon adresse e-mail et mes données personnelles soient traitées afin de m'envoyer des offres adaptées et des informations pertinentes par email",
        submit: 'Soumettre',
      },
      response: {
        success: 'Merci ! Vous recevrez dans quelques instants un e-mail pour finaliser votre inscription.',
        fail: 'Désolé, il semble que nous ayons des difficultés pour vous inscrire.',
      },
    },
    cameraBar: {
      title: 'Devenez la star, prenez votre photo maintenant ! ',
    },
    camera: {
      title: 'Prendre une photo',
      subTitle: 'et partagez-le sur nos réseaux sociaux.',
      instructions: {
        step1: ' Prendre une photo',
        step2: ' Validez votre photo',
        step3: ' Recevez votre photo par e-mail',
        step4: {
          part1: 'Si votre photo est validée,',
          part2: 'elle sera diffusée sur grand écran !',
        },
        action: 'Prendre la photo',
      },
      confirmation: {
        title: "Votre photo vient d'être envoyée !",
        social_medias: "N'oubliez pas de la partager sur les réseaux sociaux.",
      },
      reception: {
        title: 'Recevoir ma photo !',
        subTitle: 'Entrez votre adresse e-mail et recevez votre photo !',
      },
      cgu: "J'ai lu et j'accepte les conditions générales d'utilisation",
      newsletter: "S'inscrire à la newsletter",
      screen_diffusion: "Diffuser la photo sur l'écran géant",
    },
  },
};
