<script lang="ts">
declare let visioweb: any;

import { defineComponent } from 'vue';
import ResizeObserver from 'resize-observer-polyfill';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';
import project, { getKiosk } from 'glooh-globals/src/store/project';
import cache, { Cache } from 'glooh-globals/src/mixins/misc/cache';

const slug = import.meta.env.VITE_PROJECT_SLUG;

export default defineComponent({
  mixins: [cache],
  cache: {
    id: 'map--container',
    useCache: () => {
      const container = Cache.store['map--container'];
      const canvas = container?.querySelector('canvas');
      const context = canvas?.getContext('webgl');
      if (!context || context.isContextLost()) {
        return false;
      }
    },
  },
  emits: ['loaded'],
  data: () => ({
    observer: undefined as undefined | ResizeObserver,
    buildingId: undefined as string | undefined,
    floorId: undefined as string | undefined,
    loaded: false,
  }),
  methods: {
    onObjectMouseUp(_event: any, place: any) {
      const placeId = place?.options ? place.options('id') : place?.vg?.id;
      this.setActivePlace(placeId);
      this.$emit('place-selected', placeId);
    },
    async load() {
      if (!Cache.store.mapviewer) return;
      await Cache.store.mapviewer.load({
        path: `/${slug}/map/descriptor.json`,
        cameraType: 'perspective',
        onObjectMouseUp: (e: any, p: any) => this.onObjectMouseUp(e, p),
        onObjectMouseOver: (_event: any, place: any) => {
          const placeId = place?.options ? place.options('id') : place?.vg?.id;
          if (Cache.store.activePlaceId === placeId) return;
          Cache.store.mapviewer.setPlaceColor(placeId, 0xaaaaaa);
        },
        onObjectMouseOut: (_event: any, place: any) => {
          const placeId = place?.options ? place.options('id') : place?.vg?.id;
          if (Cache.store.activePlaceId === placeId) return;
          Cache.store.mapviewer.resetPlaceColor(placeId);
        },
      });
    },

    async initView() {
      if (!Cache.store.mapviewer) return;

      await Cache.store.mapviewer.setupView(this.element as HTMLElement);
      await Cache.store.mapviewer.setupMultiBuildingView({
        container: this.element as HTMLElement,
        viewType: 'multibuilding',
        animationType: 'translation',
      });

      if (Cache.store.mapviewer.cameraDrivenExplorer) Cache.store.mapviewer.cameraDrivenExplorer.setEnabled(true);
      Cache.store.mapviewer.camera.zoomManipulatorEnabled = true;
      Cache.store.mapviewer.camera.maxRadius = 700;
      Cache.store.mapviewer.camera.minRadius = 50;
      Cache.store.mapviewer.camera.rotation = 127.0894980251467;
      Cache.store.mapviewer.camera.pitch = -34.323944879270595;
    },

    async initLayout() {
      if (!Cache.store.mapviewer) return;

      const { multiBuildingView } = Cache.store.mapviewer;
      const { buildingByID } = multiBuildingView.getVenueLayout();
      const building = buildingByID[Object.keys(buildingByID)[0]];
      const { id: buildingId, defaultFloorIndex, floors } = building;

      this.buildingId = buildingId;
      this.floorId = floors[defaultFloorIndex]?.id;
    },

    initPlaces() {
      if (!Cache.store.mapviewer) return;

      const extraData = Cache.store.mapviewer.getExtraData();
      const resources = extraData?.resources?.default;
      const { places } = resources?.localized?.locale?.default || {};

      Cache.store.mapviewer.addPOI({
        url: '/img/pictos/you-are-here.png',
        position: getKiosk().youAreHere,
        floor: getKiosk().floor,
        scale: { x: 8, y: 8, z: 8 },
        alignment: { x: 0, y: 0 },
        overlay: true,
        face2D: false,
        fixed: false,
        id: 'you_are_here',
        color: '#FFFFFF',
      });

      Cache.store.places = {};

      for (const place of Object.keys(places)) {
        const shop = project.shops.all()?.find((p) => {
          const a = p?.mapwize_id?.toString().toLowerCase().replace(/\s/g, '').split(/, ?/g) ?? [];
          const b = place?.toString().toLowerCase().replace(/\s/g, '');
          return a.find((e: any) => e == b);
        });

        const name = getLocaleValue(shop?.title);

        Cache.store.places[place] = Object.assign({}, places[place], {
          floor: Cache.store.mapviewer.getPlace(place)?.vg?.floor,
          shop,
          name,
        });

        Cache.store.mapviewer.setPlaceName(place, {
          id: place,
          text: name,
          textTextureHeight: 128,
          zoomScaleVisible: 1,
          zoomScaleFactor: 1.5,
          multiline: true,
          color: '#000',
          //vgnobatch: false,
        });
      }

      Cache.store.mapviewer.setupNavigationTranslator(this.places);
    },

    initObserver() {
      this.observer = new ResizeObserver((entries) => {
        const element = entries[0].target;
        const width = parseInt(getComputedStyle(element).width, 10);
        const height = parseInt(getComputedStyle(element).height, 10);
        if (Cache.store.mapviewer) Cache.store.mapviewer.resize(width, height);
      });

      this.observer.observe(this.element as HTMLElement);
    },
    angleDifference(current: number, target: number, scale: number) {
      const diff = target - current;
      const angles = [diff, diff + scale, diff - scale];
      angles.sort((a, b) => Math.abs(a) - Math.abs(b));
      return angles[0];
    },
    async start() {
      if (!Cache.store.mapviewer) return;

      Cache.store.mapviewer.on('exploreStateWillChange', (event: any) => {
        const { mode, buildingID, floorID } = event.args.target;
        requestAnimationFrame(() => {
          if (buildingID !== undefined && buildingID !== this.buildingId) {
            this.buildingId = buildingID;
            this.resetActivePlace();
          }
          if (floorID !== undefined && floorID !== this.floorId) {
            this.floorId = floorID;
            this.resetActivePlace();
          }
        });
      });

      Cache.store.mapviewer.start();

      const { multiBuildingView } = Cache.store.mapviewer;

      const place = this.getPlaceForShop(this.$route.params?.placeId as string);
      const isRoot = place === 'you_are_here';

      if (isRoot) {
        await multiBuildingView.goTo({
          mode: 'global',
          buildingID: multiBuildingView.DEFAULT,
        });
      } else {
        multiBuildingView.goTo({
          mode: 'global',
          buildingID: multiBuildingView.DEFAULT,
          animationDuration: 0,
        });
      }
    },

    goToPlace(placeId: string, options?: any) {
      const place = Cache.store.mapviewer.getPlace(placeId);
      if (!place) {
        const poi = Cache.store.mapviewer.getPOI(placeId);
        if (poi !== undefined && poi.length > 0) {
          let goToOptions = {
            viewpoint: {
              position: {
                ...poi[0].options('position'),
                ...options,
              },
              ...options,
            },
            ...options,
          };
          const poiFloor = poi[0].options('floor');
          goToOptions.mode = 'floor';
          goToOptions.floorID = poiFloor;
          return Cache.store.mapviewer.multiBuildingView.goTo(goToOptions);
        }
      } else {
        return Cache.store.mapviewer.multiBuildingView.goTo({
          mode: 'floor',
          place: placeId,
          ...options,
        });
      }
    },

    goToFloor(floorId: string, options?: any) {
      Cache.store.mapviewer.multiBuildingView.goTo({
        mode: 'floor',
        floorID: floorId,
        ...options,
      });
    },

    getFloors(): { name: string; index: number }[] {
      if (!this.loaded) return [];
      return (Cache.store.mapviewer?.getFloors() ?? []).map((c) => c).filter((c) => c.name !== 'outside') ?? [];
    },

    async setDestination(disabled: boolean, ...dest: string[]) {
      const route = await Cache.store.mapviewer.computeRoute({
        src: { ...getKiosk().youAreHere, z: undefined, floor: getKiosk().floor },
        dst: dest,
        destinationOrder: 'optimal',
        computeNavigation: true,
        routingParameters: {
          excludedAttributes: disabled ? ['escalator', 'stairway'] : [],
        },
      });

      if (route.data.status === 200) {
        await Cache.store.mapviewer.navigationTranslator.translateInstructions(
          route.data.navigation,
          this.$i18n.locale,
        );
        this.clearRoute();
        this.setRoute(route);
        return route;
      }
      return undefined;
    },

    setRoute(route: any) {
      const currentRoute = new visioweb.Route(Cache.store.mapviewer, route.data, {});
      if (currentRoute.isValid()) {
        Cache.store.mapviewer.camera.pitch = -120;

        const mode = currentRoute.getViewpointPosition('wholeRoute');
        mode.radius *= 2;

        Cache.store.currentRoute = currentRoute;
        Cache.store.currentRoute.show();

        Cache.store.mapviewer.multiBuildingView.goTo({
          viewpoint: { position: mode, pitch: -120 },
          floorID: Cache.store.currentRoute.initialFloor,
        });
      }
    },

    async clearRoute() {
      if (Cache.store.currentRoute) {
        Cache.store.currentRoute.remove();
        Cache.store.currentRoute = undefined;
      }
    },

    setActivePlace(placeId: string) {
      if (!Cache.store.places[placeId]?.name) return;
      this.resetActivePlace();

      if (placeId) {
        Cache.store.activePlaceId = placeId;
        Cache.store.mapviewer.setPlaceColor(placeId, 0x69b4be);
      }
    },

    resetActivePlace() {
      if (Cache.store.activePlaceId) {
        Cache.store.mapviewer.resetPlaceColor(Cache.store.activePlaceId);
        Cache.store.activePlaceId = undefined;
      }
    },

    getPlaceForShop(id: string) {
      for (let key of Object.keys(Cache.store.places ?? {})) {
        if (key === id) {
          return key;
        }
        if (Cache.store.places[key]?.shop?.id === parseInt(id)) {
          return key;
        }
      }
      return 'you_are_here';
    },
  },

  async mounted() {
    if (!this.cached) {
      Cache.store.mapviewer = new visioweb.Mapviewer();

      await this.load();
      await this.initView();
      await this.initLayout();
      await this.initPlaces();
      await this.initObserver();
      await this.start();

      const container = this.element;
      const canvas = container?.querySelector('canvas');
      canvas.addEventListener('webglcontextlost', async () => {
        const route = this.$route;
        await this.$router.push({ name: 'home' });
        await this.$router.push(route);
      });

      window.getCam = () => {
        return Cache.store.mapviewer.camera;
      };
    } else {
      Cache.store.mapviewer.parameters.onObjectMouseUp = (e: any, p: any) => this.onObjectMouseUp(e, p);

      const width = parseInt(getComputedStyle(this.element).width, 10) - this.element.offsetLeft;
      const height = parseInt(getComputedStyle(this.element).height, 10) - this.element.offsetTop;
      Cache.store.mapviewer.resize(width, height);
      Cache.store.mapviewer.requestRedraw();
    }

    this.resetActivePlace();
    this.clearRoute();

    const place = this.getPlaceForShop(this.$route.params?.placeId as string);

    const {
      camera: { x, y, radius, rotation, pitch },
      floor,
    } = getKiosk();
    if (place === 'you_are_here') {
      const { multiBuildingView, camera } = Cache.store.mapviewer;
      await multiBuildingView.goTo({
        mode: 'floor',
        floorID: floor,
        noViewpoint: true,
      });

      this.setActivePlace('you_are_here');
      this.setDestination(false, 'you_are_here');

      //Cache.store.mapviewer.camera.rotation = rotation;
      //await Cache.store.mapviewer.camera.goTo({ x, y, radius, heading: rotation });

      const target = this.angleDifference(camera.rotation, getKiosk().camera.rotation, 360);
      const interval = Math.abs(500 / target);

      for (let i = 0; i < Math.abs(target); i++) {
        setTimeout(() => {
          camera.rotation = (camera.rotation + (target > 0 ? 1 : -1) + 360) % 360;
        }, i * interval);
      }

      await multiBuildingView.goTo({
        viewpoint: {
          position: { x, y, radius },
          pitch,
        },
      });
    } else {
      await this.goToPlace(place, { animationDuration: 0 });
    }
    this.loaded = true;
    this.$emit('loaded');
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
  },
});
</script>

<style lang="scss" scoped>
#map--container {
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: white;
}
</style>
