<template>
  <teleport :to="to || `#${target}-zone`">
    <div v-click-outside="close" @click.self="close" class="modal-wrapper">
      <div v-bind="$attrs" class="modal">
        <slot />
        <g-button
          class="close-btn"
          v-if="closeable"
          iconSize="2vmax"
          icon="close"
          @click="close"
          transparent
          noHover
        ></g-button>
      </div>
    </div>
  </teleport>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  emits: ['close'],
  inheritAttrs: false,
  props: {
    to: {
      type: String,
      default: undefined,
    },
    target: {
      type: String,
      default: undefined,
    },
    closeable: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    close(event: MouseEvent) {
      if (this.closeable) {
        this.$emit('close', event);
      }
    },
  },
});
</script>

<style lang="scss">
.modal-wrapper {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: var(--modal-overlay-background);

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: clamp(var(--modal-width-min), var(--modal-width), var(--modal-width-max));
    height: clamp(var(--modal-height-min), var(--modal-height), var(--modal-height-max));
    padding: var(--modal-padding);
    background: var(--modal-background);
    overflow: var(--modal-overflow);
    z-index: 500;

    .close-btn {
      position: absolute;
      top: 0;
      right: 0;

      transform: translate(100%, 0);
      color: var(--app-background) !important;
      &:hover {
        color: #fff !important;
      }
      &:active {
        background: transparent;
        color: #cfcfcf !important;
      }
    }
  }
}
</style>
