<template>
  <router-link :to="{ name: 'store', params: { id: store?.id } }">
    <div class="card_container">
      <div class="grid-container">
        <div class="container_logo">
          <cimg class="logo" :src="store?.logo" />
        </div>
        <div class="container_categorie">{{ category }}</div>
        <div class="container_name">{{ title }}</div>
        <div class="container_itinary">
          <button class="secondary_button">S'y rendre</button>
        </div>
        <div class="container_view">
          <button class="primary_button">En savoir plus</button>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

const props = defineProps({
  store: {
    type: Object,
    default: undefined,
  },
});
console.log(props.store);

const category = computed(() => {
  return props.store?.categories?.slice(0, 2).join(', ');
});

const title = computed(() => {
  return getLocaleValue(props.store?.title);
});
</script>

<style lang="scss" scoped>
.card_container {
  font-family: 'Verlag';
  color: var(--theme-color);

  width: 26rem;
  height: auto;
  border-radius: 12px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.12);
  :hover {
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.12);
  }
}
.grid-container {
  display: grid;
  grid-template-columns: 2fr 8fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  background-color: #f8faf8c3;
  border-radius: 12px;
}

.container_logo {
  justify-self: center;
  align-self: center;
  grid-area: 1 / 1 / 2 / 2;
  height: 10rem;
  padding: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

.logo {
  width: 112x;
  min-width: 112px;
  max-width: 112px;
}

.container_categorie {
  justify-self: start;
  align-self: start;
  grid-area: 1 / 2 / 2 / 3;
  margin: 1rem;

  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 120%;

  letter-spacing: 0.005em;

  color: rgba(var(--theme-color-rgb), 0.55);
}

.container_name {
  justify-self: start;
  align-self: center;
  grid-area: 1 / 2 / 2 / 3;
  margin: 1rem;
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  line-height: 120%;
}

.container_itinary {
  justify-self: start;
  align-self: end;
  grid-area: 1 / 2 / 2 / 3;
  margin: 1rem;
}

.container_view {
  justify-self: end;
  align-self: end;
  grid-area: 1 / 2 / 2 / 3;
  margin: 1rem;
}

.primary_button {
  color: var(--theme-color);
  background: none;
  border: 1px solid;
  border-radius: 999999px;
  padding: 0.25rem 0.5rem;
  font-size: 14px;
  font-weight: 700;

  cursor: pointer;
}

.secondary_button {
  color: rgba(var(--theme-color-rgb), 0.55);
  background: none;
  border: none;
  border-radius: 999999px;
  padding: 0.25rem 0rem;
  font-size: 16px;
  font-weight: 700;

  cursor: pointer;
}
</style>
