import { reactive } from 'vue';

import project from 'glooh-globals/src/store/project';

const state = reactive({
  isCached: false,
  images: {} as { [key: string]: any },
});

export async function cacheImages() {
  const allImages = [
    project.events.all(),
    project.restaurants.all(),
    project.services.all(),
    project.shops.all(),
    project.promos.all(),
    project.menus.all(),
  ]
    .flat()
    .map((c) => [c.logo, , c.icon, ...c.images, c.banner])
    .flat()
    .filter((c) => !!c);

  return new Promise((res) => {
    var Player = (window as any).Player;
    if (!Player) {
      state.images = {};
      res(true);
      return;
    }
    Player.cacheMap(allImages, {}, (images: any) => {
      state.images = images;
      res(true);
    });
  })
}

export function getImage(uri?: string) {
  if (!uri) return uri;
  const key = encodeURIComponent(uri).replace(/\./g, '/.');
  return state.images[key]?.cachedURI ?? uri;
}

export default state;
