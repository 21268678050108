<template>
  <Content full>
    <template #header>
      <h1 class="title">{{ routeParams === 'restaurants' ? $t('shops.ourRestaurants') : $t('shops.ourShops') }}</h1>
    </template>
    <div class="page--stores">
      <Filter :stores="stores">
        <template v-slot:default="store">
          <g-store-card2 class="swiper-slide" :store="store" />
        </template>
      </Filter>
    </div>
  </Content>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import Filter from '@/components/misc/Filter.vue';
import Content from '@/components/layout/content/Content.vue';

import project from 'glooh-globals/src/store/project';

const route = useRoute();

const restaurants = computed(() => project.shops.all().filter((p) => p.tags?.includes('restaurant')));

const shops = computed(() => project.shops.all().filter((p) => p.tags?.includes('shop')));

const routeParams: any = computed(() => route.params.type);

const stores = computed(() => {
  console.log('params', routeParams.type);
  switch (routeParams.value) {
    case 'restaurants':
      return restaurants.value;
    case 'shops':
      return shops.value;
    default:
      return project.shops.all();
  }
});
</script>

<style lang="scss" scoped>
.title {
  font-weight: 900;
}
.page {
  &--stores {
    --button-padding: 1vmax 4vmax;
    --navbar-display: flex;
    --button-background-active: transparent;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    height: 100%;
    overflow: hidden;
    gap: 2rem;

    :deep(.store-type) {
      margin-bottom: 2vmax;

      .navbar {
        gap: 2vmax;
      }
    }
  }
}
</style>
