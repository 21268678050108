import Fuse from 'fuse.js';
import { i18n } from '../i18n';

export function getFn(obj: object, path: string | string[]): ReadonlyArray<string> | string {
  let value = (Fuse as any).config.getFn(obj, path) as ReadonlyArray<string> | string;
  if (!value) return [];
  if (Array.isArray(value)) {
    return getLocaleValue(value, true);
  }
  if (typeof value === 'string') {
    return value
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }
  return value;
}

export function getLocaleValue(value: any, normalize: boolean = false): ReadonlyArray<string> | string {
  if (!value) return value;

  const _i18n = i18n();
  if (_i18n && Array.isArray(value)) {
    const _value = value.find((a) => a.lang === _i18n.locale)?.value;
    if (_value) {
      if (!normalize) return _value;
      return _value
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .toLowerCase();
    }
    return '';
  }
  return value;
}
