<template>
  <div class="main-footer">
    <div class="container">
      <div class="containerLeft" :class="classLeftContainer">
        <div v-if="!disabledUI && activeCamera">
          <cameraBar />
        </div>
        <div v-if="disabledUI">
          <CDPNavFooter />
        </div>
      </div>
      <div class="containerPmr" :class="classPmr" v-if="activePmr">
        <div>
          <g-button
            transparent
            noHover
            class="toggle-disability"
            :icon="footerIcon"
            iconSize="2vmax"
            @click="emit('toggle')"
          >
            {{ $t('footer.disabled') }}
          </g-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import cameraBar from '@/components/cameraBar/cameraBar.vue';
import CDPNavFooter from '@/components/layout/navigation/CDPNavFooter.vue';

import project from 'glooh-globals/src/store/project';
import { reactive } from 'vue';

const footerIcon = project.config?.routeIcons?.['disabled'] ?? 'nav-pmr';
const activePmr = project.config?.features?.['pmr'] ?? false;
const activeCamera = project.config?.features?.['camera'] ?? false;

const props = defineProps<{
  disabledUI: boolean;
}>();

const stateDisabledUI = computed(() => props.disabledUI);

let classLeftContainer = reactive({
  containerLeftBG: stateDisabledUI,
});

let classPmr = reactive({
  pmr: true,
  pmrActive: stateDisabledUI,
});

const emit = defineEmits<{
  (event: 'toggle'): void;
}>();
</script>

<style lang="scss" scoped>
.main-footer {
  width: 100%;
  background-color: white;
  padding: 20px;
  --button-direction: column !important;
}

.toggle-disability {
  height: 100%;
}

.container {
  display: grid;
  height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 0;
  grid-template-columns: 90% 10%;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas: 'containerLeft containerPmr';
  background-color: white;
}

.containerLeft {
  justify-self: start;
  align-self: stretch;
  width: 100%;
  max-width: 100%;

  grid-area: containerLeft;
  display: flex;
  align-items: left;
  background: none;
}

.containerPmr {
  justify-self: end;
  align-self: center;
  grid-area: containerPmr;
  height: 145px;
  padding: 10px;
}

.pmrActive {
  :deep(svg) {
    fill: var(--theme-color);
    stroke: var(--theme-color);
  }
  color: var(--theme-color);
}
</style>
