import axios from 'axios';
import analytics, { PageData } from 'analytics';
import googleAnalytics from '@analytics/google-analytics';
import { getKioskID } from '../../store/project';

const gloohEndpoint = 'https://in.analytics.glooh.media/events';

class GloohAnalytics {
  name = 'GloohAnalytics';
  kioskId = undefined;
  track(event: any) {
    this.forward(event.payload);
  }
  page(event: any) {
    this.forward(event.payload);
  }
  identify(event: any) {
    this.kioskId = event.payload.traits.kiosk;
    this.forward(event.payload);
  }
  forward(payload: any) {
    axios.post(gloohEndpoint, {
      activation: (import.meta as any).env.VITE_PROJECT_SLUG,
      data: JSON.stringify({
        kioskId: getKioskID(),
        ...payload,
      }),
    });
  }
}

const _analytics = analytics({
  app: 'creteil-soleil',
  version: '100',
  debug: true,
  plugins: [
    new GloohAnalytics(),
    // googleAnalytics({
    //   trackingId: 'UA-153323475-1',
    // }),
  ],
});

const Analytics = {
  page: (data?: PageData, options?: any, callback?: (...params: any[]) => any): Promise<any> => {
    //console.log('[A] - Page:', data);
    return _analytics.page(data, options, callback);
  },
  track: (eventName: string, payload?: any, options?: any, callback?: (...params: any[]) => any): Promise<any> => {
    //console.log('[A] - Track:', eventName);
    return _analytics.track(eventName, payload, options, callback);
  },
  identify: (userId: string, traits?: any, options?: any, callback?: (...params: any[]) => any): Promise<any> => {
    //console.log('[A] - Identify:', userId);
    return _analytics.identify(userId, traits, options, callback);
  },
  reset: (callback?: (...params: any[]) => any) => {
    //console.log('[A] - Reset');
    return _analytics.reset(callback);
  },
  getState: (key: string | undefined) => {
    return _analytics.getState(key);
  },
};

export function useAnalytics() {
  return {
    page: Analytics.page,
    track: Analytics.track,
    identify: Analytics.identify,
    reset: Analytics.reset,
    getState: Analytics.getState,
  };
}
