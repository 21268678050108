import { Plugin, reactive } from 'vue';
import _ from 'lodash';

import { install as installComponents } from './components';
import { install as installStore } from './store';
import { install as installDirectives } from './directives';
import { install as installPlugins } from './plugins';
import { install as installMixins } from './mixins';
import { install as installLocales } from './i18n';

export let installed: boolean = false;
export let state = reactive({
  loaded: false,
});

let plugin: Plugin = {
  async install(app, options) {
    if (installed) return;
    installed = true;
    try {
      var Player = (window as any).Player;
      if (typeof Player === 'undefined' || !Player.active) {
        const envResponse = await fetch(`http://${window.location.hostname}:${window.location.port}/env`);
        if (!envResponse || envResponse.status !== 200) return;

        const { CACHE_PORT, SERVER_PORT, KIOSK_ID, active } = await envResponse.json();

        (window as any).Player = {
          active,
          KIOSK_ID,
          CACHE_PORT,
          SERVER_PORT,
          cachedURL: async (url: string, options = {}) => {
            const response = await fetch(`http://${window.location.hostname}:${CACHE_PORT}/cachedURL`, {
              headers: { 'Content-Type': 'application/json' },
              method: 'POST',
              body: JSON.stringify({ url, options }),
            });
            if (response.status === 200) {
              const responseData = await response.json();
              return { cachedURI: responseData.cachedURI };
            }
            return { cachedURI: response };
          },
          cacheMap: async (urls: string[], options = {}, callback: (args?: any) => {}) => {
            const response = await fetch(`http://${window.location.hostname}:${CACHE_PORT}/cacheMap`, {
              headers: { 'Content-Type': 'application/json' },
              method: 'POST',
              body: JSON.stringify({ urls, options }),
            });
            if (response.status === 200) {
              const responseData = await response.json();
              return callback(responseData);
            }
            callback({});
          },
          axiosReq: async (config: any) => {
            const response = await fetch(`http://${window.location.hostname}:${CACHE_PORT}/axiosReq`, {
              headers: { 'Content-Type': 'application/json' },
              method: 'POST',
              body: JSON.stringify(config),
            });
            console.log('RESPONSE', response);
            if (response.status === 200) {
              const responseData = await response.json();
              return responseData;
            }
            return undefined;
          },
        };
      }
    } catch (ignored) {}

    if (_.get(options, 'installComponents', true)) {
      await installComponents(app, options);
    }

    if (_.get(options, 'installDirectives', true)) {
      await installDirectives(app, options);
    }

    if (_.get(options, 'installPlugins', true)) {
      await installPlugins(app, options);
    }

    if (_.get(options, 'installMixins', true)) {
      await installMixins(app, options);
    }

    await installLocales(app, options?.i18n);

    await installStore(options);

    if (options.onLoaded) {
      await options.onLoaded();
    }

    state.loaded = true;
  },
};

export default plugin;
