<template>
  <div class="title-wrapper" :class="classes">
    <slot name="left" />
    <div class="header">
      <div v-if="title" class="title">
        <span class="TextItem" v-html="title" />
      </div>
      <div v-if="typeof subTitle === 'string'" class="subtitle">
        <div v-if="left" class="line line--left"></div>
        <span v-if="subTitle" class="TextItem" v-html="subTitle" />
        <div v-if="right" class="line line--right"></div>
      </div>
    </div>
    <slot name="right" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    title: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: undefined,
    },
    vertical: {
      type: Boolean,
      default: false,
    },
    compressed: {
      type: Boolean,
      default: false,
    },
    left: {
      type: Boolean,
      default: true,
    },
    right: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    classes(): object {
      return {
        'title-wrapper--vertical': this.vertical,
        'title-wrapper--compressed': this.compressed,
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.title-wrapper {
  --button-padding: 0.25rem 1rem;

  margin: 0;
  padding: 0 var(--title-padding);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: var(--title-horizontal-spacing);

  &--vertical {
    flex-direction: column;
    justify-content: center;
    gap: var(--title-vertical-spacing);
  }

  :deep(.g-btn) {
    width: min-content;
    min-width: min-content;
    white-space: nowrap;
  }

  .header {
    overflow: hidden;
    width: 100%;

    .title {
      font-size: var(--title-font-size);
      line-height: var(--title-font-size);
      // text-transform: uppercase;
      text-align: var(--title-text-align, center);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .subtitle {
      font-size: var(--title-subtitle-font-size);
      line-height: var(--title-subtitle-font-size);
      text-transform: uppercase;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      .line {
        height: var(--title-line-height);
        min-width: var(--title-line-min-width);
        max-width: var(--title-line-max-width);

        flex: 1;
        margin: 0 var(--title-line-margin);
        background-color: var(--title-line-background);

        &--left {
          min-width: var(--title-line-min-width-left);
          max-width: var(--title-line-max-width-left);
        }

        &--right {
          min-width: var(--title-line-min-width-right);
          max-width: var(--title-line-max-width-right);
        }
      }
    }
  }

  &--compressed {
    .header {
      width: min-content;
      max-width: min-content;
    }
  }
}
</style>
