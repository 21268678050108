import enGB from "./en-GB";
import frFR from "./fr-FR";
import nlNL from "./nl-NL";
import esES from "./es-ES";

export default {
  'fr-FR': frFR,
  'en-GB': enGB,
  'nl-NL': nlNL,
  'es-ES': esES
}
