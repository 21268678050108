import { App } from 'vue';
import { createI18n, I18n } from 'vue-i18n';
import { merge } from 'lodash';
import locales from './locales';
import project from '../store/project';

let _instance: I18n<any, any, unknown> | undefined = undefined;

export function i18n() {
  return _instance?.global;
}

export async function install(app: App, options: any) {
  const availableLocales = Object.keys(locales);

  const datetimeFormats = availableLocales.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: merge((locales as any)[curr].datetimeFormats, (options?.locales || {})[curr]?.datetimeFormats),
    }),
    {},
  );

  const messages: any = availableLocales.reduce(
    (acc, curr) => ({
      ...acc,
      [curr]: merge((locales as any)[curr].messages, (options?.locales || {})[curr]?.messages),
    }),
    {},
  );

  for (let locale of availableLocales) {
    try {
      const projectLocale = await (await fetch(`/${import.meta.env.VITE_PROJECT_SLUG}/locales/${locale}.json`)).json();
      messages[locale] = merge(messages[locale], projectLocale);
    } catch (e) {}
  }

  _instance = createI18n({
    locale: options?.locale || availableLocales[0],
    fallbackLocale: options?.fallbackLocale || availableLocales[0],
    datetimeFormats: merge(datetimeFormats, options?.datetimeFormats),
    messages: merge(messages, options?.messages),
  });

  app.use(_instance);
}
