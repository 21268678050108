<template>
  <div class="main-header--stats">
    <div>
      <p class="date">{{ $d(date, 'date') }}</p>
      <span class="sep"></span>
      <span class="time">
        <b>{{ $d(date, 'time') }}</b>
      </span>
    </div>
    <span class="weather">
        <i :class="icon" />
        {{ temp }}°C
      </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';

import axios from 'axios';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  setup() {
    const date = ref(new Date());
    const temp = ref<any>();
    const icon = ref<any>();
    const updateInterval = ref<any>();
    const timeInterval = ref<any>();
    const weatherLocation = project.config.weather_location || 'Brussels,BE';

    async function update() {
      var Player = (window as any).Player;

      let url = project.config.weather_hub || `https://api.openweathermap.org/data/2.5/weather?q=${weatherLocation}&APPID=eef22cef038127876db8ff075f24aafb`;
      if (typeof Player !== 'undefined') {
        const response = (await Player.cachedURL(url, { forceUpdate: true }));
        url = response?.cachedURI ?? url;
      }

      const { data: weatherData } = await axios.get(url);
      icon.value = `owf owf-${weatherData.weather[0].id}`;
      temp.value = (weatherData.main.temp - 273.15).toFixed(0);
    }

    onMounted(async () => {
      update();
      updateInterval.value = setInterval(update, 60000);
      timeInterval.value = setInterval(() => (date.value = new Date()), 1000);
    });

    onBeforeUnmount(() => {
      if (updateInterval.value) {
        clearInterval(updateInterval.value);
      }
      if (timeInterval.value) {
        clearInterval(timeInterval.value);
      }
    });

    return { date, icon, temp };
  },
});
</script>

<style lang="scss" scoped>
.main-header {
  &--stats {
    display: flex;
    flex-direction: column;
    width: 18vmax;

    padding: 2rem 0.5rem;
    gap: 1.5rem;
    font-size: 1.75rem;
    text-transform: capitalize;
    color: white;

    p {
      text-align: center;
      font-size: 1.5rem;
      line-height: 1.5rem;
    }

    .time {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }

    .weather {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1rem;

      > i {
        width: 3rem;
        height: 3rem;
        font-size: 3rem;
      }
    }

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;

      .sep {
        background: white;
        width: 2px;
      }

      * {
        display: flex;
        gap: 0.5rem;
        align-items: center;
      }
    }
  }
}
</style>
