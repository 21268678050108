import { createApp } from 'vue';
import globals, { state } from 'glooh-globals';

import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';

import app from '@/app';
import router from '@/router';
import { cacheImages } from '@/store/cache';
import AppLoader from '@/views/AppLoader.vue';

import { getKioskID } from 'glooh-globals/src/store/project';

import 'glooh-globals/src/assets/styles/base.scss';
import '@/assets/styles/app.scss';
import 'animate.css';

const globalConfig = {
  project: {
    id: import.meta.env.VITE_PROJECT_ID,
    refreshRate: '0 * * * *',
  },
  plugins: {
    timeout: {
      verbose: false,
    },
  },
  onLoaded: async () => {
    await cacheImages();
    initSentry();
  },
};

const vueApp = createApp({
  components: {
    AppLoader,
  },
  computed: {
    loaded() {
      return state && state.loaded;
    },
    component() {
      return this.loaded ? 'router-view' : 'AppLoader';
    },
  },
  template: `<component :is="component" />`,
});

function initSentry() {
  Sentry.init({
    vueApp,
    dsn: 'https://eeb661d740804db98ae134f9d033d8cb@o571332.ingest.sentry.io/5884761',
    integrations: [
      new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['localhost', 'my-site-url.com', /^\//],
      }),
    ],
    release: 'glooh-kiosk@' + import.meta.env.npm_package_version,
    tracesSampleRate: 1.0,
  });

  Sentry.setTag('project', import.meta.env.VITE_PROJECT_SLUG);
  Sentry.setTag('kiosk_id', getKioskID());
}

vueApp.use(app);
vueApp.use(globals, globalConfig);
vueApp.use(router);
vueApp.mount('#root');
