<template>
  <div class="page--home">
    <section class="news">
      <g-title :title="$t('home.news')" compressed :right="false">
        <template #right>
          <g-button icon="arrow-right" :to="{ name: 'agendas', params: { type: 'news', filter: 'all' } }">
            {{ $t('home.seeAll') }}
          </g-button>
        </template>
      </g-title>

      <g-swiper centeredSlides>
        <g-slide v-for="(slide, i) in events" :key="i" :to="{ name: 'agenda', params: { id: slide?.id } }">
          <cimg class="banner" :src="slide.banner ?? slide.images[0]" />
          <template v-if="bannerTitle" #title>{{ getLocaleValue(slide.title) }}</template>
        </g-slide>
      </g-swiper>
    </section>

    <section class="boutiques">
      <g-title :title="$t('home.shops')" compressed :right="false">
        <template #right>
          <g-button
            icon="arrow-right"
            :to="{
              name: 'stores',
            }"
          >
            {{ $t('home.seeAll') }}
          </g-button>
        </template>
      </g-title>

      <g-swiper v-if="!separateShops" :loop="false" :centeredSlides="false">
        <g-slide v-for="(shop, i) in allShops" :key="i" :to="{ name: 'store', params: { id: shop?.id } }">
          <cimg :src="shop?.logo" />
        </g-slide>
      </g-swiper>
      <g-swiper v-if="separateShops" :loop="false" :centeredSlides="false">
        <g-slide v-for="(shop, i) in boutiques" :key="i" :to="{ name: 'store', params: { id: shop?.id } }">
          <cimg :src="shop?.logo" />
        </g-slide>
      </g-swiper>
    </section>

    <section v-if="separateShops" class="restaurants">
      <g-title :title="$t('home.restaurants')" compressed :right="false">
        <template #right>
          <g-button
            icon="arrow-right"
            :to="{
              name: 'stores',
              params: { type: 'restaurants' },
            }"
          >
            {{ $t('home.seeAll') }}
          </g-button>
        </template>
      </g-title>

      <g-swiper :loop="false" :centeredSlides="false">
        <g-slide v-for="(shop, i) in restaurants" :key="i" :to="{ name: 'store', params: { id: shop?.id } }">
          <cimg :src="shop?.logo" />
        </g-slide>
      </g-swiper>
    </section>

    <section class="services">
      <g-title :title="$t('home.services')" compressed :right="false">
        <template #right>
          <g-button icon="arrow-right" :to="{ name: 'services' }">
            {{ $t('home.seeAll') }}
          </g-button>
        </template>
      </g-title>

      <g-swiper :loop="false" :centeredSlides="false">
        <!--<g-slide v-for="(service, i) in services" :key="i" :to="{ name: 'service', params: { id: service?.id } }" style="--swiper-content-background: transparent;--swiper-content-gap: 1rem;">
          <template #content>
            <cimg :src="service?.logo" />
            <span style="color: black;">{{getLocaleValue(service.title)}}</span>
          </template>
        </g-slide>-->
        <g-service-card
          class="swiper-slide"
          v-for="(service, i) in services"
          :key="i"
          :service="service"
          action=""
          @action="$router.push({ name: 'service', params: { id: service.id } })"
        />
      </g-swiper>
    </section>

    <g-modal v-if="modalContentType && modalContentId" target="content" @close="closeModal">
      <component :is="modalContentType" :id="modalContentId" />
    </g-modal>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { getLocaleValue } from 'glooh-globals/src/utils/fuse';

import IconElevator from '@/components/icons/IconElevator.vue';
import IconThePoint from '@/components/icons/IconThePoint.vue';
import IconThePointAlt from '@/components/icons/IconThePointAlt.vue';
import IconToilet from '@/components/icons/IconToilet.vue';
import IconToiletAll from '@/components/icons/IconToiletAll.vue';
import IconMoney from '@/components/icons/IconMoney.vue';
import IconPlay from '@/components/icons/IconPlay.vue';
import IconHere from '@/components/icons/IconHere.vue';
import project from 'glooh-globals/src/store/project';

export default defineComponent({
  data: () => ({
    modalContentType: '',
    modalContentId: -1,
    bannerTitle: project.config.bannerTitle !== false,
  }),
  components: {
    IconElevator,
    IconThePoint,
    IconThePointAlt,
    IconToilet,
    IconToiletAll,
    IconMoney,
    IconPlay,
    IconHere,
  },
  computed: {
    separateShops(): boolean {
      return project.config.separateShopsBoutiques;
    },

    allShops(): any[] {
      return project.shops.all();
    },
    boutiques(): any[] {
      return project.shops.all().filter((a) => !a?.isRestaurant);
    },
    restaurants(): any[] {
      return project.shops.all().filter((a) => a?.isRestaurant);
    },

    services(): any[] {
      return project.services.all();
    },
    events(): any[] {
      const events = [...project.events.active()];
      const now = new Date();

      return events
        .filter((a) => a.tags?.includes('featured'))
        .filter((e) => e.banner || e.images[0])
        .filter((e) => !e.date_to || new Date(e.date_to) > now) // Exclude events with date_to in the past
        .sort((a, b) => {
          const aDateFrom = new Date(a.date_from).getTime();
          const bDateFrom = new Date(b.date_from).getTime();
          if (!a.date_to) return 1; // Place events with null or undefined date_to at the end
          if (!b.date_to) return -1; // Place events with null or undefined date_to at the end
          return bDateFrom - aDateFrom; // Sort by date_from in descending order
        })
        .slice(0, Math.min(20, events.length));
    },
    quick_menu(): any {
      return project.config.quick_menu;
    },
  },
  methods: {
    getLocaleValue,
    closeModal() {
      this.$router.push({ params: { view: '', id: '' } });
    },
  },
});
</script>

<style lang="scss" scoped>
.page {
  &--home {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    //  @see https://github.com/gloohmedia/glooh/issues/316
    // Try to fix crop banner image
    //gap: 2.5vmax;

    flex: 1;

    height: 100%;
    overflow: auto;

    :deep(.swiper-slide) {
      background-color: transparent;
      .swiper-slide--image {
        img.banner {
          height: auto;
          object-fit: cover;
          object-position: center;
          width: 83vw;
        }
      }
    }

    --title-text-align: left;
    --title-line-max-width-left: 2rem;

    section {
      display: flex;
      flex-direction: column;
      margin-top: 2vmax;
      gap: 2.5rem;

      --button-direction: row-reverse;

      /* --button-background: rgb(56, 131, 73);  */
      --button-background: #fed931;

      .title-wrapper {
        padding: var(--swiper-navigation-padding);
      }

      &.services {
        //flex-direction: row;
        .title-wrapper {
          // flex-direction: column;
          //padding: 0;
          //padding-left: 3vmax;
        }
      }

      &:not(.news) {
        .swiper-container {
          .swiper-slide {
            margin: 0 0.5vmax;
            background: #fff;
            width: 10vmax;
            min-width: 10vmax;
            min-height: 10vmax;

            img {
              width: 75%;
              height: 75%;
              object-fit: contain;
            }
          }
        }

        &.services {
          :deep(.swiper-container) {
            --button-direction: column;

            .swiper-slide {
              width: 8vmax;
              height: 8vmax;
              min-width: 8vmax;
              min-height: 8vmax;
              justify-content: center;

              span {
                white-space: initial;
              }
            }
          }
        }

        &.discover {
          .swiper-container {
            .swiper-slide {
              width: 20vmax;
              height: 7vmax;
              min-width: 20vmax;
              min-height: 7vmax;
              gap: 0.25vmax;

              justify-content: center;
              flex-direction: column;
            }
          }
        }
      }

      &.news {
        --swiper-content-padding: 2vmax;

        flex: 1 0;
        overflow: hidden;

        :deep(.swiper) {
          flex: 1 0;
          --button-background: transparent;

          .swiper-container {
            height: 100%;

            .swiper-slide {
              width: 66rem;
              min-width: 66rem;

              max-height: 100%;
              margin: 1rem;
              //  @see https://github.com/gloohmedia/glooh/issues/316
              // fix crop banner image
              //padding: 0 4rem;

              .content {
                padding: 0 !important;
              }

              span {
                display: grid;
                grid-template-columns: 1fr;
                width: 100%;
                height: 100%;
                overflow: hidden;

                .swiper-slide--image {
                  width: 100%;
                  grid-row-start: 1;
                  grid-column-start: 1;
                }
              }
              .swiper-slide--content {
                object-fit: contain;
                position: relative;
                grid-row-start: 1;
                grid-column-start: 1;
              }

              .swiper-slide--image img {
                object-fit: contain;
                position: relative;
              }
            }
          }
        }
      }
    }
  }
}

@media (orientation: landscape) {
  .page {
    &--home {
      flex-wrap: nowrap;
      flex-direction: row;
      gap: 2vmax;

      section {
        margin-top: 0vmax;
      }

      .left-panel {
        width: 19rem;
        min-width: 19rem;
        .main {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          justify-content: flex-end;
          font-size: 1.75rem;
          line-height: 1.75rem;
          font-weight: bold;
          padding-bottom: 4rem;
          color: black;

          flex: 1;
          gap: 1rem;
          background: #bad6c8 url('/#{$projectSlug}/img/map-preview.png') no-repeat;
          background-size: cover;
          background-position: center center;
          filter: grayscale(0.5);

          svg {
            width: 6rem;
          }
        }

        .actions {
          min-height: 300px;
          display: grid;
          grid-template-columns: 50% 50%;
          grid-template-rows: 50% 50%;
          gap: 10px;

          .action {
            background: #222d33;
            color: white;
            width: 140px;
            height: 140px;
            overflow: hidden;
            padding: 1rem;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 1rem;

            svg {
              width: 80%;
              object-fit: contain;
            }
          }
        }
      }

      .left-panel,
      .right-panel {
        display: flex;
        flex-direction: column;

        gap: 3rem;
        overflow: hidden;
        padding: 1rem 0;

        .news {
          --swiper-content-background: transparent;

          flex: unset;
          height: 21rem;

          :deep(.swiper-slide--content) {
            align-items: start;
            padding-left: 12rem;

            .title {
              font-size: 4rem;
              text-align: left;
              white-space: break-spaces;
              width: 90%;

              small {
                font-weight: normal;
                font-size: 2rem;
              }
            }

            &::before {
              content: ' ';
              position: absolute;
              top: -22rem;
              left: -10rem;
              width: 40rem;
              height: 90rem;
              background: var(--theme-color);
              transform: rotate(-45deg);
              transform-origin: center center;
              opacity: 0.9;
            }
          }
        }
      }

      .left-panel {
        gap: 2vmax;
      }

      .right-panel {
        justify-content: space-between;
      }
    }
  }
}
</style>
