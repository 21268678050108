<template>
  <component :is="to || href ? 'g-button' : 'div'" class="swiper-slide" :href="href" :to="to">
    <slot name="slide">
      <div v-if="$slots.default" class="swiper-slide--image">
        <slot />
      </div>
      <div
        v-if="$slots.content || $slots.title || $slots.subtitle || $slots.text || $slots.caption"
        class="swiper-slide--content"
      >
        <slot name="content" />
        <h1 v-if="$slots.title" class="title">
          <slot name="title">Title</slot>
        </h1>
        <h5 v-if="$slots.subtitle" class="subtitle">
          <slot name="subtitle">Subtitle</slot>
        </h5>
        <p v-if="$slots.text" class="text">
          <slot name="text">Some random text paragraph.</slot>
        </p>
        <small v-if="$slots.caption" class="caption">
          <slot name="caption">Some random caption.</slot>
        </small>
      </div>
    </slot>
  </component>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    to: {
      default: undefined,
    },
    href: {
      type: String,
      default: undefined,
    },
  },
});
</script>

<style lang="scss" scoped>
.swiper-slide {
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;

  &--content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition: all 0.25s linear;

    color: white;
    background: var(--swiper-content-background);
    padding: var(--swiper-content-padding);
    gap: var(--swiper-content-gap);

    .title {
      line-height: 1em;
      padding-bottom: 0;
      max-width: 80%;
      white-space: normal;
    }

    .subtitle {
      padding-top: 0;
    }

    .caption {
      position: absolute;
      left: 2rem;
      bottom: 3.5rem;
      right: 2rem;
      padding: 1rem;
      background: var(--swiper-caption-background);

      font-size: 1.25rem;
      line-height: 1.25rem;
      max-width: 1000px;
      margin: auto;
    }
  }
}

:deep(.swiper-slide--image) {
  display: flex;
  justify-content: center;
  align-items: center;

  overflow: hidden;
  font-size: 0;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.banner {
      background: transparent;
      object-fit: cover;
    }
  }
}
</style>
