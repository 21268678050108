<template>
  <div id="app" :class="{ disabledUI, timedOut: wasTimedOut }">
    <template v-if="$route.meta.full">
      <router-view />
    </template>
    <template v-else>
      <cdp-header @disabled="setDisabled" @abled="setAbled" />
      <div class="main-content">
        <div class="page-wrapper" :style="{ backgroundImage: `url(/${slug}/img/background.png)` }">
          <router-view />
        </div>
        <g-modal-zone zone="content" />
      </div>
      <cdp-footer class="footerContainer" @toggle="toggleDisabled" :disabledUI="disabledUI" />
    </template>

    <TimedOut />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import CDPHeader from '@/components/layout/header/CDPHeader.vue';
import CDPFooter from '@/components/layout/footer/CDPFooter.vue';
import TimedOut from '@/components/layout/content/TimedOut.vue';
import { useAnalytics } from 'glooh-globals/src/plugins/misc/analytics';
import project from 'glooh-globals/src/store/project';
import routes from '@/assets/routes';

export default defineComponent({
  components: {
    'cdp-header': CDPHeader,
    'cdp-footer': CDPFooter,
    TimedOut,
  },
  setup() {
    const route = useRoute(),
      router = useRouter(),
      analytics = useAnalytics();

    const disabledUI = ref(false);

    function setDisabled() {
      disabledUI.value = true;
      analytics.track('ui.disabled', { disabled: true });
    }

    function setAbled() {
      disabledUI.value = false;
      analytics.track('ui.disabled', { disabled: false });
    }

    function toggleDisabled() {
      if (disabledUI.value) {
        setAbled();
      } else {
        setDisabled();
      }
    }

    function onTimeout() {
      if (route.name !== 'home') router.push({ name: 'home' });
      disabledUI.value = false;
    }

    onMounted(() => {
      if (import.meta.env.PROD && route.name !== 'home') {
        router.push({ name: 'home' });
      }
    });

    return {
      setDisabled,
      setAbled,
      toggleDisabled,
      routes,
      disabledUI,
      onTimeout,
      footer: project.config?.footer,
      slug: import.meta.env.VITE_PROJECT_SLUG,
      disabledIcon: project.config?.routeIcons?.['disabled'] ?? 'nav-pmr',
    };
  },
});
</script>

<style lang="scss" scoped>
#app {
  flex-direction: column;
  &.timedOut {
    pointer-events: none !important;
    * {
      pointer-events: none !important;
    }
  }
}

.main-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  height: 100%;

  .page-wrapper {
    height: 100%;
    overflow: hidden;
  }

  .modal-zone {
    --modal-overflow: visible;
    --modal-width-max: 100%;
    --modal-height-max: 100%;
    --modal-overlay-background: #000000cc;
    z-index: 1000;
  }
}

.footerContainer {
  background-color: white;
  z-index: 100;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#app.disabledUI {
  .footer {
    .footer-nav {
      display: flex;
    }
  }

  :deep(.main-nav) {
    display: none !important;
  }
}
</style>
